import { computed, Ref, useContext } from '@nuxtjs/composition-api';
import {
  ApiError,
  getStoriesAsArray,
  getStoryBlokVersion,
  useAllContent,
} from '@unified-commerce/gpc-vue-storefront-storyblok';

interface SbCategoryDefinition {
  id: string;
  category: {
    name: string;
    description: string;
    product_category: {
      title: string;
    };
    thumb_image: {
      filename: string;
      alt: string;
    };
    seo_title: string;
    seo_description: string;
  };
}

export interface UseSbCategoriesProps {
  id: string;
}

export interface UseSbCategoriesReturnProps {
  search: () => Promise<void>;
  error: Ref<ApiError | null>;
  sbCategories: Ref<SbCategoryDefinition[]>;
  firstLevelCategories: Ref<SbCategoryDefinition[]>;
  getSbCategoryName: (category: SbCategoryDefinition | null) => string;
  getSbCategoryDescription: (category: SbCategoryDefinition | null) => string;
  getSbCategoryThumbImage: (category: SbCategoryDefinition | null) => string;
  getSbCategoryThumbAlt: (category: SbCategoryDefinition | null) => string;
  getSbCategoryById: (categoryId: string) => SbCategoryDefinition | null;
  getSbCategorySeoTitle: (category: SbCategoryDefinition | null) => string;
  getSbCategorySeoDescription: (category: SbCategoryDefinition | null) => string;
}

// eslint-disable-next-line max-lines-per-function
const useSbCategories = (
  { id }: UseSbCategoriesProps = { id: 'categories-sb' },
): UseSbCategoriesReturnProps => {
  const { $config } = useContext();
  const { search: searchSbCategories, content: readOnlySbCategories, error } = useAllContent(id);

  const sbCategoriesError = computed(() => error.value.search as ApiError);

  const search = async () => {
    await searchSbCategories({
      version: getStoryBlokVersion($config.ENV),
      filterQuery: {
        component: {
          in: 'Category',
        },
      },
      sortBy: 'name:asc',
    });
  };

  const sbCategories = computed(() => {
    return getStoriesAsArray(readOnlySbCategories.value)
      .map((sbCategory) => {
        const shopifyCategory =
          sbCategory?.content?.product_category?.[0].shopify_category?.items?.[0];
        if (shopifyCategory?.id) {
          // Only adding necessary fields into the category object. Can add any other field if/when needed in the category object
          return {
            id: shopifyCategory.id,
            category: {
              name: sbCategory?.content?.name,
              description: sbCategory?.content?.description,
              product_category: {
                title:
                  sbCategory?.content?.product_category?.[0].shopify_category?.items?.[0]?.name,
              },
              thumb_image: {
                filename: sbCategory?.content?.thumb_image?.filename,
                alt: sbCategory?.content?.thumb_image?.alt,
              },
              seo_title: sbCategory?.content?.seo_title,
              seo_description: sbCategory?.content?.seo_description,
            },
          };
        }
      })
      .filter((category): category is SbCategoryDefinition => category !== undefined); // Type guard to filter out undefined entries
  });

  const firstLevelCategories = computed(() => {
    return sbCategories?.value?.filter((sbCategory) => {
      return sbCategory?.category?.product_category?.title?.split('>').length === 2;
    });
  });

  const getSbCategoryById = (categoryId: string) => {
    return sbCategories?.value?.find((sbCategory) => sbCategory.id === categoryId) || null;
  };

  const getSbCategoryName = (sbCategory: SbCategoryDefinition | null) => {
    return sbCategory?.category?.name || '';
  };

  const getSbCategoryDescription = (sbCategory: SbCategoryDefinition | null) => {
    return sbCategory?.category?.description || '';
  };

  const getSbCategoryThumbImage = (sbCategory: SbCategoryDefinition | null) => {
    return sbCategory?.category?.thumb_image?.filename || '';
  };

  const getSbCategoryThumbAlt = (sbCategory: SbCategoryDefinition | null) => {
    return sbCategory?.category?.thumb_image?.alt || '';
  };

  const getSbCategorySeoTitle = (sbCategory: SbCategoryDefinition | null) => {
    return sbCategory?.category?.seo_title || '';
  };

  const getSbCategorySeoDescription = (sbCategory: SbCategoryDefinition | null) => {
    return sbCategory?.category?.seo_description || '';
  };

  return {
    search,
    error: sbCategoriesError,
    sbCategories,
    firstLevelCategories,
    getSbCategoryName,
    getSbCategoryDescription,
    getSbCategoryThumbImage,
    getSbCategoryThumbAlt,
    getSbCategoryById,
    getSbCategorySeoTitle,
    getSbCategorySeoDescription,
  };
};

export default useSbCategories;
