<template v-if="itemsCount">
  <!-- component ejected from Vuestorefront and edited to enable responsive bullets -->
  <div class="carousel-with-header">
    <slot
      name="header"
      v-bind="{
        prev: () => go('prev'),
        next: () => go('next'),
        prevDisabled: !shouldSlideLeft,
        nextDisabled: !shouldSlideRight,
      }"
    />
    <div class="carousel" :style="cssProps">
      <div ref="glide" class="carousel__track" :class="{ 'carousel__track--peek': shouldPeek }">
        <div class="glide__track" data-glide-el="track">
          <ul class="glide__slides">
            <slot />
          </ul>
        </div>
        <div v-if="enableBullets && numberOfScenes > 1" class="carousel__bullets">
          <button
            v-for="n in numberOfScenes"
            :key="n"
            class="carousel__bullet-button"
            @click="go(indexForScene(n))"
          >
            <slot
              name="carousel-bullet"
              v-bind="{ isActive: isBulletActive(n), imageIndex: n ? n - 1 : 0 }"
            >
              <div
                :class="{
                  'carousel__bullet--active': isBulletActive(n),
                }"
                class="carousel__bullet"
              />
            </slot>
          </button>
          <button
            v-if="hasTrailingScene"
            class="carousel__bullet-button"
            @click="go(indexForTrailingScene())"
          >
            <slot
              name="carousel-bullet"
              v-bind="{ isActive: isBulletActive(hasTrailingScene), imageIndex: hasTrailingScene }"
            >
              <div
                class="carousel__bullet"
                :class="{
                  'carousel__bullet--active': isTrailingSceneBulletActive(),
                }"
              />
            </slot>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Glide from '@glidejs/glide';
import { defineComponent } from '@nuxtjs/composition-api';

const GLIDEJS_XS_BREAKPOINT = 576;
const GLIDEJS_SM_BREAKPOINT = 768;
const GLIDEJS_MD_BREAKPOINT = 1200;
const GLIDEJS_PEEK_AMOUNT = 100;

export default defineComponent({
  name: 'BaseCarouselWithHeader',
  props: {
    settings: {
      type: Object,
      default: () => ({}),
    },
    enableBullets: {
      type: Boolean,
      default: false,
    },
    activeBulletColor: {
      type: String,
      default: 'red',
    },
    inactiveBulletColor: {
      type: String,
      default: 'gray',
    },
    mobilePerView: {
      type: Number,
      default: 1,
    },
    tabletPerView: {
      type: Number,
      default: 3,
    },
    desktopPerView: {
      type: Number,
      default: 5,
    },
    mobileGap: {
      type: Number,
      default: 20,
    },
    tabletGap: {
      type: Number,
      default: 20,
    },
    desktopGap: {
      type: Number,
      default: 30,
    },
    enablePeeking: {
      type: Boolean,
      default: true,
    },
    fromWebsiteBanner: {
      type: Boolean,
      default: false,
    },
  },
  data(props) {
    const remainingDots = props.tabletPerView - props.mobilePerView;
    const itemsCount = this.$slots?.default?.filter((slot) => slot.tag)?.length ?? 0;

    return {
      glide: null,
      defaultSettings: {
        type: 'slider',
        rewind: false,
        perView: props.desktopPerView,
        slidePerPage: true,
        gap: props.desktopGap,
        breakpoints: {
          [GLIDEJS_XS_BREAKPOINT]: {
            perView: Math.min(itemsCount, props.mobilePerView),
            gap: props.mobileGap,
            peek: {
              before: 0,
              after: props.mobilePerView === 1 && !props.enableBullets ? GLIDEJS_PEEK_AMOUNT : 0,
            },
          },
          [GLIDEJS_SM_BREAKPOINT]: {
            perView: Math.min(
              itemsCount,
              remainingDots <= 1 ? 1 : Math.round((props.tabletPerView + props.mobilePerView) / 2),
            ),
            gap: props.tabletGap,
            peek: {
              before: 0,
              after: 0,
            },
          },
          [GLIDEJS_MD_BREAKPOINT]: {
            perView: Math.min(itemsCount, props.tabletPerView),
            gap: props.tabletGap,
            peek: {
              before: 0,
              after: 0,
            },
          },
        },
      },
    };
  },

  computed: {
    itemsCount() {
      return this.$slots?.default?.filter((slot) => slot.tag)?.length ?? 0;
    },
    hasTrailingScene() {
      return this.itemsCount % this.perView !== 0;
    },
    perView(props) {
      if (this.glide) return this.glide.settings.perView;
      return props.desktopPerView;
    },
    numberOfScenes() {
      return Math.floor(this.itemsCount / this.perView) || 0;
    },
    cssProps(props) {
      return {
        '--active-bullet-color': props.activeBulletColor,
        '--inactive-bullet-color': props.inactiveBulletColor,
      };
    },
    currentIndex() {
      if (!this.glide) return;
      return this.glide.index;
    },
    mergedOptions() {
      let breakpoints = { ...this.defaultSettings.breakpoints };
      if (this.settings.breakpoints) {
        breakpoints = { ...breakpoints, ...this.settings.breakpoints };
      }
      return {
        ...this.defaultSettings,
        ...this.settings,
        breakpoints: breakpoints,
      };
    },
    shouldPeek() {
      if (!this.enablePeeking) {
        return false;
      }
      return !this.enableBullets && this.perView === 1;
    },
    shouldSlideRight() {
      if (this.settings.type === 'carousel' && this.settings.rewind) {
        return true;
      }
      return this.currentIndex !== this.indexForTrailingScene();
    },
    shouldSlideLeft() {
      if (this.settings.type === 'carousel' && this.settings.rewind) {
        return true;
      }
      return !!this.currentIndex;
    },
    shouldRenderSliderArrows() {
      if (this.settings.type === 'carousel' && this.itemsCount > this.perView) {
        return true;
      }
      return this.itemsCount > this.perView;
    },
    shouldPeekSlider() {
      if (!this.enablePeeking) {
        return 0;
      }
      return this.mobilePerView === 1 && !this.enableBullets ? GLIDEJS_PEEK_AMOUNT : 0;
    },
    iconColorLeft() {
      if (this.fromWebsiteBanner) {
        return 'var(--c-light)';
      }

      return this.shouldSlideLeft ? 'var(--c-gray-variant)' : 'var(--c-light-darken)';
    },
    iconColorRight() {
      if (this.fromWebsiteBanner) {
        return 'var(--c-light)';
      }

      return this.shouldSlideRight ? 'var(--c-gray-variant)' : 'var(--c-light-darken)';
    },
  },
  watch: {
    perView() {
      if (this.currentIndex > this.indexForTrailingScene()) {
        this.go(this.indexForTrailingScene());
      }
    },
  },
  mounted: function () {
    this.$nextTick(() => {
      const glide = new Glide(this.$refs.glide, this.mergedOptions);
      const size = this.$slots?.default?.filter((slot) => slot.tag)?.length ?? 0;
      if (size <= glide.settings.perView) {
        glide.settings.perView = size;
        glide.settings.rewind = false;
      }
      glide.mount();
      glide.on('run.before', (move) => {
        const { slidePerPage, rewind, type } = this.mergedOptions;
        if (!slidePerPage) return;
        const { perView } = glide.settings;
        if (!perView > 1) return;
        const { direction } = move;
        let page, newIndex;

        if (type === 'carousel' && rewind) {
          page = Math.ceil(glide.index / perView);
          newIndex = page * perView + (direction === '>' ? perView : -perView);
          if (newIndex >= size) {
            newIndex = 0;
          } else if (newIndex < 0 || newIndex + perView > size) {
            newIndex = size - perView;
          }
        } else {
          if (direction === '>') {
            newIndex = glide.index + perView;
            if (this.hasTrailingScene && newIndex > this.indexForTrailingScene()) {
              newIndex = this.indexForTrailingScene();
            }
            if (!this.hasTrailingScene && newIndex > this.indexForScene(this.numberOfScenes)) {
              newIndex = this.indexForScene(this.numberOfScenes);
            }
          } else if (direction === '<') {
            newIndex = glide.index - perView;
            if (this.hasTrailingScene && glide.index === this.indexForTrailingScene()) {
              newIndex = glide.index - (size % perView);
            }
            if (newIndex < 0) newIndex = 0;
          }
        }
        if (direction === '>' || direction === '<') {
          move.direction = '=';
          move.steps = newIndex;
        }

        const isGoingToLastSlide = newIndex === size - 1;

        if (isGoingToLastSlide) {
          glide.settings.breakpoints[GLIDEJS_XS_BREAKPOINT].peek.before = this.shouldPeekSlider;
          glide.settings.breakpoints[GLIDEJS_XS_BREAKPOINT].peek.after = 0;
        } else {
          glide.settings.breakpoints[GLIDEJS_XS_BREAKPOINT].peek.before = 0;
          glide.settings.breakpoints[GLIDEJS_XS_BREAKPOINT].peek.after = this.shouldPeekSlider;
        }
      });
      this.glide = glide;
    });
  },
  methods: {
    indexForScene(n) {
      return (n - 1) * this.perView;
    },
    indexForTrailingScene() {
      return this.itemsCount - this.perView;
    },
    isTrailingSceneBulletActive() {
      return this.currentIndex === this.itemsCount - this.perView;
    },
    isBulletActive(n) {
      return (
        n - 1 === Math.floor(this.currentIndex / this.perView) &&
        (!this.hasTrailingScene || this.currentIndex !== this.itemsCount - this.perView)
      );
    },
    go(direct) {
      if (!this.glide) return;
      switch (direct) {
        case 'prev':
          this.glide.go('<');
          break;
        case 'next':
          this.glide.go('>');
          break;
        default:
          this.glide.go(`=${direct}`);
      }
    },
  },
});
</script>
<style scoped lang="scss">
.carousel-with-header {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.carousel {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;

  &__track {
    width: 100%;
  }

  &__bullets {
    width: fit-content;
    display: flex;
    gap: var(--carousel-bullets-gap, 0px);
    margin: auto;
    margin-top: var(--carousel-bullets-margin-top, var(--spacer-3base));
    flex-wrap: wrap;
  }

  &__bullet-button {
    cursor: pointer;
    background: var(--carousel-bullet-button-background, none);
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--carousel-bullet-button-height, 18px);
    width: var(--carousel-bullet-button-width, 18px);
    @media (hover: hover) {
      &:hover {
        transform: translate3d(0px, -2px, 0px);
      }
    }
    transition: transform 0.2s ease;
  }

  &__bullet {
    cursor: pointer;
    border: none;
    border-radius: 50%;
    min-width: 10px;
    height: 10px;
    background-color: var(--inactive-bullet-color);
    &--active {
      background-color: var(--active-bullet-color);
    }
  }
}

.glide__slides {
  min-width: 0;
}

::v-deep .is-disabled--button {
  background: white;
}
</style>
