import { IVariantInventoryLevels } from '@unified-commerce/gpc-vue-storefront-shopify-admin';

export const checkStockAtStore = (
  inventory: IVariantInventoryLevels[],
  storeName?: string,
): number => {
  if (!storeName || !inventory) return 0;
  return inventory
    .map((x) => x.inventoryLevels[storeName])
    .reduce((acc, val) => Math.min(acc, val));
};
