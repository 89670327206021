import { IProductResult } from '@unified-commerce/gpc-vue-storefront-search-io';

export const sortBySkuOrder = (skus: string[]) => (a: IProductResult, b: IProductResult) => {
  const indexA = skus.indexOf(a.record.metafield_plytix__sku as string);
  const indexB = skus.indexOf(b.record.metafield_plytix__sku as string);

  // If either sku is not found in the skuValues array, preserve their original order
  if (indexA === -1 || indexB === -1) {
    return 0;
  }

  return indexA - indexB;
};
