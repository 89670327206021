import { LatLng } from '~/types/stores';

export const getBrowserLocation = (): Promise<LatLng> => {
  if (!navigator.geolocation) {
    return Promise.reject('Geolocation is not supported by your browser');
  }
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        resolve({ lat: position.coords.latitude, lng: position.coords.longitude });
      },
      (err) => {
        reject(err);
      },
    );
  });
};
