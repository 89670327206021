import { library } from '@fortawesome/fontawesome-svg-core';
import { faFacebook, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import {
  faAngleDown,
  faAngleUp,
  faBoxOpen,
  faCartPlus,
  faCartShopping,
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleCheck,
  faCircleUser,
  faCircleX,
  faCircleXmark,
  faEdit,
  faFilter,
  faGear,
  faHouse,
  faInfo,
  faLocationDot,
  faLockKeyhole,
  faPhone,
  faPlay,
  faSealExclamation,
  faSearch,
  faShippingFast,
  faTag,
  faTimes,
  faTrashCan,
  faUser,
  faWarehouse,
  faXmark,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Vue from 'vue';

library.add(
  faXmark,
  faChevronRight,
  faChevronLeft,
  faPlay,
  faAngleDown,
  faCircleCheck,
  faCircleUser,
  faHouse,
  faPhone,
  faLocationDot,
  faCartShopping,
  faTrashCan,
  faLockKeyhole,
  faUser,
  faChevronUp,
  faChevronDown,
  faWarehouse,
  faCircleX,
  faSealExclamation,
  faFilter,
  faCircleXmark,
  faCartPlus,
  faSearch,
  faShippingFast,
  faTag,
  faTimes,
  faGear,
  faInfo,
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faEdit,
  faYoutube,
  faFacebook,
  faInstagram,
  faAngleUp,
  faBoxOpen,
);

Vue.component('FontAwesomeIcon', FontAwesomeIcon);
