import { Product, productGetters } from '@unified-commerce/gpc-vue-storefront-shopify';

import {
  IVariantOption,
  ShopifyBundleVariantSummary,
} from '~/components/@types/IBundledProductOption';

export const getShopifyBundleVariantSummary = (product: Product): ShopifyBundleVariantSummary => ({
  name: productGetters.getName(product),
  image: productGetters.getCoverImage(product, 'medium'),
  options: productGetters.getOptions(product, true) as unknown as IVariantOption[],
});
