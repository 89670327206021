import { SEARCH_CATEGORY_HANDLE_SEPARATOR } from '~/constants';

export const getCategoryURLPath = (ancestorHandles: string[], categoryHandle: string): string => {
  const filteredHandles = ancestorHandles.filter((handle) => handle !== '');

  if (filteredHandles.length > 0) {
    const urlPaths = [...filteredHandles, categoryHandle].map((handle, i, handles) => {
      const previousHandle = handles[i - 1];

      return handle.replace(previousHandle + SEARCH_CATEGORY_HANDLE_SEPARATOR, '');
    });

    return `/${urlPaths.join('/')}`;
  }

  return `/${categoryHandle}`;
};
