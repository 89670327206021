<template>
  <div class="login-form">
    <BaseHeading
      :level="1"
      :title="hasForgottenPassword ? 'Reset password' : 'Sign in to your account'"
      class="heading"
    />

    <div v-if="!hasForgottenPassword" class="form">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form class="form" @submit.prevent="handleSubmit(handleLogin)">
          <div class="login-form__inputs">
            <p>Email</p>
            <ValidationProvider v-slot="{ errors }" rules="required|email">
              <BaseInput
                v-model="form.username"
                :valid="!errors[0]"
                :error-message="errors[0]"
                class="login-form__input"
                placeholder="Enter your email"
                name="email"
              />
            </ValidationProvider>
            <p>Password</p>
            <ValidationProvider v-slot="{ errors }" rules="required|min:5">
              <BaseInput
                v-model="form.password"
                :valid="!errors[0]"
                :error-message="errors[0]"
                class="login-form__input"
                type="password"
                placeholder="Enter your Password"
                name="password"
              />
            </ValidationProvider>
            <BaseButton
              class="sf-button--text login-form__input--password-btn"
              @click="showForgotPasswordForm"
              >Having trouble signing in?</BaseButton
            >
          </div>
          <div v-if="error.login" class="login-form__error">
            {{ error.login }}
          </div>
          <BaseButton
            type="submit"
            class="sf-button--full-width login-form__sign-in-btn"
            :disabled="isLoading"
          >
            <BaseLoader class="login-form__loader" :loading="isLoading"> Sign In </BaseLoader>
          </BaseButton>
        </form>
      </ValidationObserver>
    </div>
    <div v-else class="forgot-password">
      <ForgotPasswordForm />

      <div class="action">
        <BaseButton class="sf-button--text" @click="showLoginForm">
          {{ $t('Sign In') }}
        </BaseButton>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, reactive, ref, useRoute } from '@nuxtjs/composition-api';
import { BaseButton, BaseHeading, BaseInput, BaseLoader } from '@unified-commerce/gpc-ui-library';
import { useCart, useUser } from '@unified-commerce/gpc-vue-storefront-shopify';
import dayjs from 'dayjs';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { email, min, required } from 'vee-validate/dist/rules';

import ForgotPasswordForm from '~/components/ForgotPasswordForm.vue';
import { useUiHelpers } from '~/composables';
import { IS_LOGIN_FROM_CHECKOUT_COOKIE, IS_LOGIN_FROM_CHECKOUT_QUERY_PARAM } from '~/constants';

import { loginUser } from '../helpers/login/loginUser';

extend('email', {
  ...email,
  message: 'Invalid email',
});

extend('min', {
  ...min,
  validate: (value) => (value ?? '').trim().length >= 2,
  message: 'The field should have at least {length} characters',
});

extend('required', {
  ...required,
  message: 'This field is required',
});

export default defineComponent({
  name: 'LoginForm',
  components: {
    BaseHeading,
    ForgotPasswordForm,
    BaseInput,
    BaseButton,
    BaseLoader,
    ValidationProvider,
    ValidationObserver,
  },
  setup(_props, context) {
    const form = ref({});
    const route = useRoute();
    const isLoading = ref(false);
    const hasForgottenPassword = ref(false);
    const userEmail = ref('');
    const rememberMe = ref(false);
    const { user, login, error: userError } = useUser();
    const { cart } = useCart();
    const { getQueryParam, getCookieName } = useUiHelpers();

    const cookies = context.root.$cookies;

    const error = reactive({
      login: null,
      forgotPassword: null,
    });

    const resetErrorValues = () => {
      error.login = null;
      error.forgotPassword = null;
    };

    const success = reactive({
      forgotPassword: null,
    });

    const resetSuccessValues = () => {
      success.forgotPassword = null;
    };

    const handleLogin = async () => {
      resetErrorValues();
      resetSuccessValues();

      const result = await loginUser(
        isLoading,
        route,
        login,
        form,
        cookies,
        user,
        userError,
        error,
        cart,
        context.root.$config.appKey,
      );

      if (!result) {
        return;
      }

      if (user.value.multipass) {
        window.location.href = user.value.multipass.url;
      } else {
        isLoading.value = false;
        context.emit('success');
        window.location.reload();
      }
    };

    const showLoginForm = () => {
      hasForgottenPassword.value = false;
    };

    const showForgotPasswordForm = () => {
      hasForgottenPassword.value = true;
    };

    onMounted(() => {
      const isLoginFromCheckout = getQueryParam(IS_LOGIN_FROM_CHECKOUT_QUERY_PARAM);

      if (isLoginFromCheckout) {
        cookies.set(
          getCookieName(context.root.$config.appKey, IS_LOGIN_FROM_CHECKOUT_COOKIE),
          'yes',
          {
            path: '/',
            expires: dayjs().add(2, 'minutes').toDate(),
          },
        );
      }
    });

    return {
      form,
      error,
      userError,
      success,
      isLoading,
      rememberMe,
      handleLogin,
      userEmail,
      hasForgottenPassword,
      showLoginForm,
      showForgotPasswordForm,
    };
  },
});
</script>

<style lang="scss" scoped>
.login-form {
  width: 100%;

  .heading {
    --heading-title-font-size: 36px;
    --heading-title-line-height: 40px;
    --heading-title-margin-bottom: 8px;
    --heading-description-font-size: 12px;
    --heading-description-line-height: 16px;
    --heading-description-margin: 0;
    margin-bottom: 16px;

    & h1 {
      font-weight: 700;
      line-height: 40px;
    }

    @include md-devices-min {
      --heading-title-font-size: 40px;
      --heading-title-line-height: 44px;
      --heading-description-font-size: 16px;
      --heading-description-line-height: 24px;
      margin-bottom: 48px;
    }
  }

  &__error {
    color: var(--c-support-50);
    margin-bottom: var(--spacer-xs);
  }

  &__inputs {
    p {
      font-weight: 400;
      margin: 0;
      font-size: 14px;
      line-height: 20px;

      @include md-devices-min {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 4px;
      }
    }
  }

  &__input {
    @include md-devices-min {
      margin-bottom: 16px;
    }
  }

  &__input--password-btn {
    color: var(--c-primary-700);
    font-weight: 400;
    margin-bottom: 36px;
  }

  &__sign-in-btn {
    margin-top: var(--spacer-base);
    --button-text-transform: capitalize;
    --button-background: var(--c-black);
    --button-padding: 14px 20px;
    --button-font-size: 18px;
    --button-line-height: 20px;
    --button-font-weight: 700;
  }

  &__loader {
    --loader-spinner-stroke: var(--c-light-variant);
  }
}

.form,
.forgot-password {
  width: 100%;
}

::v-deep .sf-input__error-message {
  color: var(--c-support-50);
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}
</style>
