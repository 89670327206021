import { ISearchFilters } from '~/types/searchio';

export const SEARCH_CATEGORY_TENANT_PREFIX_TITLE = 'AMX Categories';
export const SEARCH_CATEGORY_TENANT_PREFIX_HANDLE = 'amx-categories-';
export const SEARCH_CATEGORY_PROMOTIONS_PREFIX_HANDLE = 'promotions-';
export const SEARCH_CATEGORY_PATH_SEPERATOR = '>';
export const SEARCH_TOP_LEVEL_PROMOTION_CATEGORY_TITLE = `${SEARCH_CATEGORY_TENANT_PREFIX_TITLE}>Promotions`;
export const SEARCH_PROMOTION_ACTIVE_TAG = 'sales_promo_active';
export const SEARCH_CATEGORY_HANDLE_SEPARATOR = '-';
export const SEARCH_CATEGORY_QUERY_SEPARATOR = '/';
export const SEARCH_CATEGORY_FILTER = 'collection_titles';
export const SEARCH_BRAND_PREFIX_TITLE = 'AMX Categories>Brand Categories';
export const SEARCH_DEFAULT_TITLE_OPTION = 'Default Title';

export const BRANDS_CATEGORY_IDENTIFIER = 'Brands';
export const BRAND_CATEGORIES_CATEGORY_IDENTIFIER = 'Brand Categories';

export const SEARCH_PRICE_FILTER = 'max_price';
export const SEARCH_FILTER_TITLE_PREFIX = 'count.';
export const SEARCH_FILTER_VARIANT_OPTION_PREFIX = 'variant_options';
export const SEARCH_FILTER_PRODUCT_OPTION_PREFIX = 'option';
export const CATEGORY_ROUTE_PREFIX = 'motorcycle-gear';
export const STORYBLOK_CATEGORY_ROUTE_PREFIX = `content/categories`;
export const IS_LOGIN_FROM_CHECKOUT_QUERY_PARAM = 'from-checkout';
export const IS_LOGIN_FROM_CHECKOUT_COOKIE = 'isloginfromcheckout';
export const PAGE_QUERY_PARAM = 'page';
export const CATEGORY_QUERY_PARAM = 'category';
export const PRICE_RANGE_QUERY_PARAM = 'price-range';
export const QUANTITY_FILTER_KEY = 'inventory_quantity';
export const QUANTITY_QUERY_PARAM = 'quantity';
export const MAX_PRICE_RANGE_QUERY_PREFIX = 'up-to-';
export const MIN_PRICE_RANGE_QUERY_PREFIX = 'above-';
export const SEARCH_QUERY_PARAM = 'q';
export const PRODUCT_TYPE_QUERY_PARAM = 'product_type';
export const SIZE_OPTION_QUERY_PARAM = 'size';
export const COLOUR_OPTION_QUERY_PARAM = 'colour';
export const VENDOR_QUERY_PARAM = 'vendor';
export const STYLE_QUERY_PARAM = 'style';
export const STYLE_FILTER_KEY = 'variant_metafield_plytix_amx_style';
export const SORT_BY_QUERY_PARAM = 'sort-by';
export const SEARCH_DEFAULT_FILTERS: ISearchFilters[] = [
  {
    filterKey: 'collection_titles',
    queryParameterName: CATEGORY_QUERY_PARAM,
    canProductHaveMultipleValues: true,
    extendWithPromotionFilter: true,
  },
  {
    filterKey: 'product_type',
    queryParameterName: PRODUCT_TYPE_QUERY_PARAM,
    canProductHaveMultipleValues: false,
  },
  {
    filterKey: `${SEARCH_FILTER_VARIANT_OPTION_PREFIX}_1`,
    queryParameterName: `${SEARCH_FILTER_VARIANT_OPTION_PREFIX}_1`,
    canProductHaveMultipleValues: true,
  },
  {
    filterKey: `${SEARCH_FILTER_VARIANT_OPTION_PREFIX}_2`,
    queryParameterName: `${SEARCH_FILTER_VARIANT_OPTION_PREFIX}_2`,
    canProductHaveMultipleValues: true,
  },
  {
    filterKey: `${SEARCH_FILTER_VARIANT_OPTION_PREFIX}_3`,
    queryParameterName: `${SEARCH_FILTER_VARIANT_OPTION_PREFIX}_3`,
    canProductHaveMultipleValues: true,
  },
  {
    filterKey: `${SEARCH_FILTER_PRODUCT_OPTION_PREFIX}_size`,
    queryParameterName: SIZE_OPTION_QUERY_PARAM,
    canProductHaveMultipleValues: true,
  },
  {
    filterKey: `${SEARCH_FILTER_PRODUCT_OPTION_PREFIX}_colour`,
    queryParameterName: COLOUR_OPTION_QUERY_PARAM,
    canProductHaveMultipleValues: true,
  },
  {
    filterKey: QUANTITY_FILTER_KEY,
    queryParameterName: QUANTITY_QUERY_PARAM,
    canProductHaveMultipleValues: false,
    label: 'Availability',
  },
  {
    filterKey: 'vendor',
    queryParameterName: VENDOR_QUERY_PARAM,
    canProductHaveMultipleValues: false,
    label: 'Brands',
  },
  {
    filterKey: STYLE_FILTER_KEY,
    queryParameterName: STYLE_QUERY_PARAM,
    canProductHaveMultipleValues: true,
    label: 'Style',
  },
];
export const SEARCH_PRICE_RANGES = [
  {
    maxPrice: 50,
  },
  {
    minPrice: 50,
    maxPrice: 100,
  },
  {
    minPrice: 100,
    maxPrice: 500,
  },
  {
    minPrice: 500,
    maxPrice: 1000,
  },
  {
    minPrice: 1000,
    maxPrice: 3000,
  },
  {
    minPrice: 3000,
    maxPrice: 7500,
  },
];
export const SORT_OPTION_LIST = [
  {
    label: 'Most Relevant',
    value: 'most_relevant',
    queryParamName: null,
  },
  {
    label: 'Alphabetically, A - Z',
    value: 'title',
    queryParamName: 'title-ascending',
  },
  {
    label: 'Alphabetically, Z - A',
    value: '-title',
    queryParamName: 'title-descending',
  },
  {
    label: 'Price, low - high',
    value: 'max_price',
    queryParamName: 'price-ascending',
  },
  {
    label: 'Price, high - low',
    value: '-max_price',
    queryParamName: 'price-descending',
  },
  {
    label: 'Date, old - new',
    value: 'created_at',
    queryParamName: 'created-ascending',
  },
  {
    label: 'Date, new - old',
    value: '-created_at',
    queryParamName: 'created-descending',
  },
];
