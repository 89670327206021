import { Ref, ref } from '@nuxtjs/composition-api';

import { load, save } from '~/helpers/localStorage';
import { LatLng } from '~/types/stores';

const myLocation = ref<LatLng | null>(load('myLocation'));

export const useMyLocation = (): {
  myLocation: Ref<LatLng | null>;
  saveLocation: (store: LatLng) => void;
} => {
  const saveLocation = (latLng: LatLng): void => {
    myLocation.value = latLng;
    save('myLocation', latLng);
  };

  return { myLocation, saveLocation };
};
