import { IVehicleSelection } from '../VehicleSelection/types';

export const selections: IVehicleSelection[] = [
  {
    title: 'Make',
    label: 'MAKE',
    queryField: 'make',
    isDisabled: false,
    options: [],
  },
  {
    title: 'Model',
    label: 'MODEL',
    queryField: 'model',
    isDisabled: true,
    options: [],
  },
  {
    title: 'Year',
    label: 'YEAR',
    queryField: 'year',
    isDisabled: true,
    options: [],
  },
  {
    title: 'Series',
    label: 'SERIES',
    queryField: 'series',
    isDisabled: true,
    options: [],
  },
  {
    title: 'BodyType',
    label: 'BODY',
    queryField: 'bodyType',
    isDisabled: true,
    options: [],
  },
  {
    title: 'EngineCode',
    label: 'ENGINE',
    queryField: 'engine',
    isDisabled: true,
    options: [],
  },
];
