import { render, staticRenderFns } from "./ProductRecommendation.vue?vue&type=template&id=e567a42c&scoped=true&"
import script from "./ProductRecommendation.vue?vue&type=script&lang=ts&"
export * from "./ProductRecommendation.vue?vue&type=script&lang=ts&"
import style0 from "./ProductRecommendation.vue?vue&type=style&index=0&id=e567a42c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e567a42c",
  null
  
)

export default component.exports