import { SEARCH_CATEGORY_PATH_SEPERATOR } from '~/constants';

/**
 * Product Information Mangement (PIM) integration pushes to Shopify category hierarchy. E.g. `"4WD247 Categories>Audio & Navigation>Navigation"`.
 *
 * It flattens the hierarchy to a single string since Shopify has no concept of nested categories.
 *
 * In UI app we need to account for that when rebuilding UI interface elements like routes for category levels, breadcrumbs etc.
 *
 * Also, all brands have concept of the parent category which is used only to differentiate between different brands. For 4x4 it is `"4WD247 Categories"`.
 */
export const getCategoryExactTitle = (categoryTitle: string): string => {
  const titles = categoryTitle.split(SEARCH_CATEGORY_PATH_SEPERATOR);

  if (titles.length === 1) {
    return titles.join('');
  }

  const [, ...categoryTitles] = titles;

  return categoryTitles[categoryTitles.length - 1];
};
