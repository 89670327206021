/* eslint-disable max-lines-per-function */
import { UserVehicleSelectionKey } from '@unified-commerce/gpc-vue-storefront-search-io';
import Cookies from 'js-cookie';

import {
  IUserSelectedVehicle,
  IUserSelectedVehicleOutdated,
} from '~/components/VehicleSelection/types';
import { useUiState } from '~/composables';

const useMyGarage = () => {
  const { setUserSelectedVehicle, setVehiclesInGarage } = useUiState();

  const addVehicle = async (vehicleToAdd: IUserSelectedVehicle) => {
    const currentVehiclesInGarage = await getAndResetVehicles(true);

    const index = currentVehiclesInGarage.findIndex(
      (vehicle) => vehicle.KtypNr === vehicleToAdd.KtypNr,
    );

    vehicleToAdd.selected = true;
    if (index === -1) {
      currentVehiclesInGarage.push(vehicleToAdd);
    } else {
      currentVehiclesInGarage[index].selected = true;
    }

    setVehicleSelectionsInCookie(JSON.stringify(currentVehiclesInGarage));
    setVehiclesSelectionsInLs(JSON.stringify(currentVehiclesInGarage));
    setUserSelectedVehicle(vehicleToAdd);
    setVehiclesInGarage(currentVehiclesInGarage);
  };

  const setVehiclesSelectionsInLs = (selections: string): Promise<unknown> => {
    return Promise.resolve(window.localStorage.setItem(UserVehicleSelectionKey, selections));
  };

  const setVehicleSelectionsInCookie = (selections: string): Promise<unknown> => {
    return Promise.resolve(Cookies.set(UserVehicleSelectionKey, selections, { expires: 365 * 2 }));
  };

  const getAllVehicleSelectionsFromLs = (): Promise<IUserSelectedVehicle[] | null> => {
    const userVehicleSelection = window.localStorage.getItem(UserVehicleSelectionKey);
    return Promise.resolve(userVehicleSelection ? JSON.parse(userVehicleSelection) : null);
  };

  const getVehicleSelectionFromCookie = (): Promise<IUserSelectedVehicle | null> => {
    const userVehicleSelectionsString = Cookies.get(UserVehicleSelectionKey);

    try {
      if (userVehicleSelectionsString) {
        const selectedUserVehicleSelection = getUserSelectedVehicle(userVehicleSelectionsString);
        return Promise.resolve(selectedUserVehicleSelection);
      } else {
        return Promise.resolve(null);
      }
    } catch (err) {
      return Promise.resolve(null);
    }
  };

  const getUserSelectedVehicle = (userSelectedVehicleString: string): IUserSelectedVehicle => {
    const userVehicleSelections: IUserSelectedVehicle[] = JSON.parse(userSelectedVehicleString);
    const selectedUserVehicleSelection = userVehicleSelections.filter(
      (vehicle) => vehicle.selected,
    )[0];

    return selectedUserVehicleSelection;
  };

  const removeVehicleFromGarage = async (vehicleToRemove: IUserSelectedVehicle) => {
    const currentVehicles = await getAndResetVehicles(false);

    const vehiclesRemaining = currentVehicles.filter(
      (vehicle) => vehicle.KtypNr !== vehicleToRemove.KtypNr,
    );
    setVehicleSelectionsInCookie(JSON.stringify(vehiclesRemaining));
    setVehiclesSelectionsInLs(JSON.stringify(vehiclesRemaining));
    if (vehicleToRemove.selected) {
      setUserSelectedVehicle(null);
    }
    setVehiclesInGarage(vehiclesRemaining);
  };

  const resetVehiclesFromGarage = async () => {
    const currentVehiclesInGarage = await getAndResetVehicles(true);
    setVehicleSelectionsInCookie(JSON.stringify(currentVehiclesInGarage));
    setVehiclesSelectionsInLs(JSON.stringify(currentVehiclesInGarage));
    setUserSelectedVehicle(null);
    setVehiclesInGarage(currentVehiclesInGarage);
  };

  const getAndResetVehicles = async (shouldReset: boolean) => {
    const currentVehiclesInGarage: IUserSelectedVehicle[] =
      (await getAllVehicleSelectionsFromLs()) || [];
    if (shouldReset) {
      currentVehiclesInGarage.forEach((vehicleSelection) => {
        vehicleSelection.selected = false;
      });
    }
    return currentVehiclesInGarage;
  };

  const syncSelectionCookieWithLocalstorage = () => {
    const selectionInCookie = Cookies.get(UserVehicleSelectionKey);
    const selectionInLs = window.localStorage.getItem(UserVehicleSelectionKey);
    if (selectionInLs && !selectionInCookie) {
      setVehicleSelectionsInCookie(selectionInLs);
    }
  };

  const getOutdatedSelection = (): IUserSelectedVehicleOutdated | null => {
    const userVehicleSelectionsString = Cookies.get(UserVehicleSelectionKey);
    try {
      if (userVehicleSelectionsString) {
        const selectedUserVehicleSelection = JSON.parse(userVehicleSelectionsString);
        if (!Array.isArray(selectedUserVehicleSelection)) {
          return selectedUserVehicleSelection;
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (err) {
      return null;
    }
  };

  return {
    addVehicle,
    resetVehiclesFromGarage,
    removeVehicleFromGarage,
    getUserSelectedVehicle,
    getVehicleSelectionFromCookie,
    setVehicleSelectionsInCookie,
    setVehiclesSelectionsInLs,
    getAllVehicleSelectionsFromLs,
    syncSelectionCookieWithLocalstorage,
    getOutdatedSelection,
  };
};

export default useMyGarage;
