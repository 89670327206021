var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"carousel-with-header"},[_vm._t("header",null,null,{
      prev: function () { return _vm.go('prev'); },
      next: function () { return _vm.go('next'); },
      prevDisabled: !_vm.shouldSlideLeft,
      nextDisabled: !_vm.shouldSlideRight,
    }),_vm._v(" "),_c('div',{staticClass:"carousel",style:(_vm.cssProps)},[_c('div',{ref:"glide",staticClass:"carousel__track",class:{ 'carousel__track--peek': _vm.shouldPeek }},[_c('div',{staticClass:"glide__track",attrs:{"data-glide-el":"track"}},[_c('ul',{staticClass:"glide__slides"},[_vm._t("default")],2)]),_vm._v(" "),(_vm.enableBullets && _vm.numberOfScenes > 1)?_c('div',{staticClass:"carousel__bullets"},[_vm._l((_vm.numberOfScenes),function(n){return _c('button',{key:n,staticClass:"carousel__bullet-button",on:{"click":function($event){_vm.go(_vm.indexForScene(n))}}},[_vm._t("carousel-bullet",function(){return [_c('div',{staticClass:"carousel__bullet",class:{
                'carousel__bullet--active': _vm.isBulletActive(n),
              }})]},null,{ isActive: _vm.isBulletActive(n), imageIndex: n ? n - 1 : 0 })],2)}),_vm._v(" "),(_vm.hasTrailingScene)?_c('button',{staticClass:"carousel__bullet-button",on:{"click":function($event){_vm.go(_vm.indexForTrailingScene())}}},[_vm._t("carousel-bullet",function(){return [_c('div',{staticClass:"carousel__bullet",class:{
                'carousel__bullet--active': _vm.isTrailingSceneBulletActive(),
              }})]},null,{ isActive: _vm.isBulletActive(_vm.hasTrailingScene), imageIndex: _vm.hasTrailingScene })],2):_vm._e()],2):_vm._e()])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }