/* eslint-disable max-lines-per-function */
/* istanbul ignore file */
import { computed, ComputedRef, Ref } from '@nuxtjs/composition-api';
import { Logger, sharedRef } from '@vue-storefront/core';

import useUiHelpers from '../../useUiHelpers';
import {
  FilterSetupConfig,
  IUseQueryParamFilters,
  QueryFilterOption,
  QueryFilters,
  UseQueryParamFiltersRefs,
} from './types';

const useQueryParamFilters = (cacheId: string): IUseQueryParamFilters => {
  const { getCurrentQuery, getByString, getQueryURL } = useUiHelpers();

  const filters: Ref<QueryFilters> = sharedRef({}, `${cacheId}-useFilters`);

  const refs: UseQueryParamFiltersRefs = {
    filters,
  };

  const defaultOption: ComputedRef<QueryFilterOption | null> = computed(() => {
    const filterParams = Object.keys(filters.value);
    let defaultOption: QueryFilterOption | null = null;
    filterParams.forEach((param) => {
      filters.value[param].options.forEach((option) => {
        if (option.default) defaultOption = option;
      });
    });
    return defaultOption;
  });

  const activeFilters = computed(() => {
    const currentQuery = getCurrentQuery();
    if (!currentQuery) {
      if (defaultOption.value) return [defaultOption.value];
      return [];
    }

    const currentQueryEntries = Object.entries(currentQuery);
    const filterParams = Object.keys(filters.value);
    const activeFilters: QueryFilterOption[] = [];
    currentQueryEntries.forEach(([param, value]) => {
      if (filterParams.includes(param) && typeof value === 'string') {
        const activeFilter: QueryFilterOption = {
          value: value,
          target: filters.value[param].target,
          queryParam: param,
        };

        // do a seperate search through options to find the label (NOTE: this is done like this instead of searching options to begin with, as on page load options is an empty array and ative filters is used for filtering queries before page load)
        filters.value[param]?.options?.forEach((option: QueryFilterOption) => {
          if (option.value === value) {
            activeFilter.label = option.label;
          }
        });

        activeFilters.push(activeFilter);
      }
    });

    if (!activeFilters.length && defaultOption.value) {
      activeFilters.push(defaultOption.value);
    }

    return activeFilters;
  });

  const setup = (refs: UseQueryParamFiltersRefs) => (params: Record<string, FilterSetupConfig>) => {
    Object.entries(params).forEach(([param, conf]) => {
      refs.filters.value[param] = {
        queryParam: param,
        label: conf.label,
        target: conf.target,
        options: [],
      };
    });
  };

  const setOptions =
    (refs: UseQueryParamFiltersRefs) => (param: string, options: Partial<QueryFilterOption>[]) => {
      options.forEach((option) => {
        option.target = refs.filters.value[param].target;
        option.queryParam = param;
      });
      refs.filters.value[param].options = options as QueryFilterOption[];
    };

  const getAddFilterURL = (
    incomingFilter: QueryFilterOption,
    paramsToRemove: string[] = [],
  ): string | undefined => {
    const allParams = Object.keys(filters.value);

    if (incomingFilter.default) return getQueryURL([...allParams, ...paramsToRemove]);
    return getQueryURL([...allParams, ...paramsToRemove], {
      [incomingFilter.queryParam]: incomingFilter.value,
    });
  };

  const getRemoveFilterURL = (
    outgoingFilter: QueryFilterOption,
    paramsToRemove: string[] = [],
  ): string | undefined => {
    return getQueryURL([outgoingFilter.queryParam, ...paramsToRemove]);
  };

  const getClearFilterURL = (paramsToRemove: string[] = []): string | undefined => {
    const allParams = Object.keys(filters.value);
    return getQueryURL([...allParams, ...paramsToRemove]);
  };

  const extractOptions = (
    targets: Record<any, any>[],
    labelFieldPath: string,
    valueFieldPath: string,
    labelModifier: (label: any) => string = (label) => label,
    valueModifier: (value: any) => string = (value) => value,
  ) => {
    const options: Partial<QueryFilterOption>[] = targets.map((targetObj) => {
      const label = getByString(targetObj, labelFieldPath);
      const value = getByString(targetObj, valueFieldPath);
      if (typeof label != 'string') {
        Logger.error(
          'useFilters/extractOptions',
          `labelField is not of type string: received ${typeof label}`,
        );
      }
      if (typeof value != 'string') {
        Logger.error(
          'useFilters/extractOptions',
          `valueField is not of type string: received ${typeof value}`,
        );
      }
      return {
        label: labelModifier(label),
        value: valueModifier(value),
      };
    });
    return options;
  };

  const getFilterLabel = (filterOption: QueryFilterOption) => {
    if (!filterOption) return undefined;
    if (filterOption?.label) return filterOption.label;
    let filterLabel: string | undefined;
    const params = Object.keys(filters.value);
    params.forEach((param) =>
      filters.value[param]?.options?.forEach((option: QueryFilterOption) => {
        if (option.value === filterOption.value) {
          filterLabel = option.label;
        }
      }),
    );
    return filterLabel;
  };

  return {
    setup: setup(refs),
    setOptions: setOptions(refs),
    filters: computed(() => filters.value),
    activeFilters,
    extractOptions,
    getFilterLabel,
    getAddFilterURL,
    getRemoveFilterURL,
    getClearFilterURL,
  };
};

export default useQueryParamFilters;
