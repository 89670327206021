import { Ref } from '@nuxtjs/composition-api';
import { useSearch } from '@unified-commerce/gpc-vue-storefront-search-io';
import { Product, productGetters, useProduct } from '@unified-commerce/gpc-vue-storefront-shopify';
import { sharedRef } from '@vue-storefront/core';

import { IBundledProductOption } from '~/components/@types/IBundledProductOption';
import { mergeSearchIOAndShopifyProducts } from '~/composables/useBundledProductOptions/mergeSearchIOAndShopifyProducts';
import { idToProductGid } from '~/composables/useBundledProductOptions/shopifyGidUtils';
import { sortBySkuOrder } from '~/composables/useBundledProductOptions/sortBySkuOrder';

export const useBundledProductOptions = (uniqueKey: string) => {
  const bundledProductOptions: Ref<IBundledProductOption[] | null> = sharedRef(
    null,
    `bundledProductOptions-${uniqueKey}`,
  );

  const {
    search: searchIOSearch,
    result: searchIOResult,
    error: searchIOError,
  } = useSearch(`bundledProductsSearchIO`);

  const {
    search: shopifyProductSearch,
    products,
    error: shopifyProductError,
  } = useProduct(`bundledProducts`);

  const getBundledProductOptions = async (product: Product) => {
    const bundles = productGetters.getBundledProductsInformation(product);
    const skus = bundles.map(({ sku }) => sku).filter(Boolean) as string[];
    const escapedASKUs = skus.map((sku) => `"${sku}"`).join(',');
    if (!escapedASKUs.length) return;

    await searchIOSearch({
      filter: `metafield_plytix__sku IN (${escapedASKUs})`,
    });
    if (searchIOError.value.search) throw searchIOError.value.search;
    if (!searchIOResult?.value) return;

    const ids = searchIOResult?.value.results
      .sort(sortBySkuOrder(skus))
      .map(({ record }) => idToProductGid(record.id));
    await shopifyProductSearch({ ids });
    if (shopifyProductError?.value?.search) throw shopifyProductError.value.search;

    bundledProductOptions.value = mergeSearchIOAndShopifyProducts(
      bundles,
      searchIOResult?.value.results,
      products.value as unknown as Product[],
    );
  };

  return {
    bundledProductOptions,
    getBundledProductOptions,
  };
};
