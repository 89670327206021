var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Container',[_c('CardSection',{attrs:{"title":_vm.title,"description":_vm.description,"link":_vm.linkUrl,"link-text":_vm.link_text,"mobile":{
      cardMode: 'scroll',
      cardFlexBasis: '128px',
    },"tablet":{
      cardFlexBasis: '25%',
      showSteppers: _vm.categories.length > 4,
    },"desktop":{
      cardFlexBasis: '24.9%',
    }}},_vm._l((_vm.categories),function(category,i){return _c('CategoryCard',{key:i,attrs:{"title":category.content.name,"image-src":_vm.getStoryblokImageURL(category.content.thumb_image.filename, { width: 250 }),"image-alt":category.content.thumb_image.alt,"link":_vm.getCategoryCarouselLink(category)}})}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }