/* eslint-disable max-lines-per-function */
/* eslint-disable @typescript-eslint/no-empty-function */
/* istanbul ignore file */
import { computed, reactive } from '@nuxtjs/composition-api';
import { UserAddress } from '@unified-commerce/gpc-vue-storefront-shopify';

import { IUserSelectedVehicle } from '~/components/VehicleSelection/types';
import { useMyGarage, useUiHelpers } from '~/composables';
import stringParseClone from '~/helpers/stringParseClone';

const { lockPageScrollOnMobileOnly, unlockPageScrollOnMobileOnly } = useUiHelpers();

const state = reactive({
  isCartSidebarOpen: false,
  isRecentlyAddedSidebarOpen: false,
  isVehicleSelectionSidebarOpen: false,
  isWishlistSidebarOpen: false,
  isGarageSelectionSidebarOpen: false,
  isGarageModalOpen: false,
  isAccountModalOpen: false,
  isRemoveAddressModalOpen: false,
  addressToRemove: null as UserAddress | null,
  isSignup: false,
  isLogoutModalOpen: false,
  isCategoryGridView: true,
  isFilterSidebarOpen: false,
  isNavigationSidebarOpen: false,
  isStoresSidebarOpen: false,
  userSelectedVehicle: null as IUserSelectedVehicle | null,
  vehiclesInGarage: null as IUserSelectedVehicle[] | null,
  vehicleAskus: null as { askus?: string[]; kTypeNr?: string } | null,
  recentlyAddedProduct: null as {
    variantId: string;
    name: string;
    brand: string;
    price: number;
    specialPrice: number | null;
    partNumber: string;
    sku: string;
    img?: string;
  } | null,
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useUiState = () => {
  const isCartSidebarOpen = computed(() => state.isCartSidebarOpen);
  const toggleCartSidebar = () => {
    state.isCartSidebarOpen = !state.isCartSidebarOpen;
    if (state.isCartSidebarOpen) {
      lockPageScrollOnMobileOnly();
    } else {
      unlockPageScrollOnMobileOnly();
    }
  };

  const isVehicleSelectionSidebarOpen = computed(() => state.isVehicleSelectionSidebarOpen);
  const toggleVehicleSelectionSidebar = () => {
    state.isVehicleSelectionSidebarOpen = !state.isVehicleSelectionSidebarOpen;
    if (state.isVehicleSelectionSidebarOpen) {
      lockPageScrollOnMobileOnly();
    } else {
      unlockPageScrollOnMobileOnly();
    }
  };

  const isWishlistSidebarOpen = computed(() => state.isWishlistSidebarOpen);
  const toggleWishlistSidebar = () => {
    state.isWishlistSidebarOpen = !state.isWishlistSidebarOpen;
  };

  const isNavigationSidebarOpen = computed(() => state.isNavigationSidebarOpen);
  const toggleNavigationSidebar = () => {
    state.isNavigationSidebarOpen = !state.isNavigationSidebarOpen;
  };

  const isGarageSelectionSidebarOpen = computed(() => state.isGarageSelectionSidebarOpen);
  const toggleGarageSelectionSidebar = () => {
    state.isGarageSelectionSidebarOpen = !state.isGarageSelectionSidebarOpen;
    if (state.isGarageSelectionSidebarOpen) {
      lockPageScrollOnMobileOnly();
    } else {
      unlockPageScrollOnMobileOnly();
    }
  };

  const isStoresSidebarOpen = computed(() => state.isStoresSidebarOpen);
  const toggleStoresSidebar = (open?: boolean) => {
    state.isStoresSidebarOpen = typeof open === 'boolean' ? open : !state.isStoresSidebarOpen;
  };

  const isGarageModalOpen = computed(() => state.isGarageModalOpen);
  const setGarageModalOpen = (isOpen: boolean) => {
    state.isGarageModalOpen = isOpen;
  };

  const isAccountModalOpen = computed(() => state.isAccountModalOpen);

  const showAccountModal = () => {
    state.isAccountModalOpen = true;
  };
  const toggleAccountModal = () => {
    state.isAccountModalOpen = !state.isAccountModalOpen;
    if (state.isAccountModalOpen) {
      lockPageScrollOnMobileOnly();
    } else {
      unlockPageScrollOnMobileOnly();
    }
  };

  const isRemoveAddressModalOpen = computed(() => state.isRemoveAddressModalOpen);
  const addressToRemove = computed(() => stringParseClone(state.addressToRemove));
  const showRemoveAddressModal = (address: UserAddress) => {
    state.isRemoveAddressModalOpen = true;
    state.addressToRemove = address;
  };
  const toggleRemoveAddressModal = () => {
    state.isRemoveAddressModalOpen = !state.isRemoveAddressModalOpen;
    if (state.isRemoveAddressModalOpen) {
      lockPageScrollOnMobileOnly();
    } else {
      state.addressToRemove = null;
      unlockPageScrollOnMobileOnly();
    }
  };

  const isSignup = computed(() => state.isSignup);

  const setIsSignup = (boolean: boolean) => {
    state.isSignup = boolean;
  };

  const isLogoutModalOpen = computed(() => state.isLogoutModalOpen);
  const showLogoutModal = () => {
    state.isLogoutModalOpen = true;
  };
  const toggleLogoutModal = () => {
    state.isLogoutModalOpen = !state.isLogoutModalOpen;
  };
  const closeLogoutModal = () => {
    state.isLogoutModalOpen = false;
  };

  const isCategoryGridView = computed(() => state.isCategoryGridView);
  const toggleCategoryGridView = () => {
    state.isCategoryGridView = !state.isCategoryGridView;
  };

  const isFilterSidebarOpen = computed(() => state.isFilterSidebarOpen);
  const toggleFilterSidebar = () => {
    state.isFilterSidebarOpen = !state.isFilterSidebarOpen;
  };

  const userSelectedVehicle = computed(() => state.userSelectedVehicle);

  const setUserSelectedVehicle = (selectedVehicle: IUserSelectedVehicle | null) => {
    state.userSelectedVehicle = selectedVehicle;
  };

  const vehiclesInGarage = computed(() => state.vehiclesInGarage);
  const setVehiclesInGarage = (vehicles: IUserSelectedVehicle[] | null) => {
    state.vehiclesInGarage = vehicles;
  };

  const initInBrowserState = async () => {
    useMyGarage().syncSelectionCookieWithLocalstorage();
    // check first if old user selection
    const outdatedVehicleSelection = useMyGarage().getOutdatedSelection();
    if (outdatedVehicleSelection) {
      // port the previous selection and align it with new data shape
      const vehicleToAdd: IUserSelectedVehicle = stringParseClone(outdatedVehicleSelection);
      vehicleToAdd.selected = true;
      vehicleToAdd.model = vehicleToAdd.name;
      useMyGarage().setVehicleSelectionsInCookie(JSON.stringify([vehicleToAdd]));
      useMyGarage().setVehiclesSelectionsInLs(JSON.stringify([vehicleToAdd]));
    }

    const userVehicleSelection = await useMyGarage().getVehicleSelectionFromCookie();
    const vehiclesInGarage = await useMyGarage().getAllVehicleSelectionsFromLs();
    setVehiclesInGarage(vehiclesInGarage);
    if (userVehicleSelection) {
      setUserSelectedVehicle(userVehicleSelection);
    }
  };

  const vehicleAskus = computed(() => state.vehicleAskus);
  const setVehicleAskus = (askus: string[], kTypeNr: string) => {
    state.vehicleAskus = { askus, kTypeNr };
  };
  const clearVehicleAskus = () => {
    state.vehicleAskus = null;
  };

  const recentlyAddedProduct = computed(() => state.recentlyAddedProduct);
  const isRecentlyAddedSidebarOpen = computed(() => !!state.recentlyAddedProduct);
  const setRecentlyAddedProduct = (
    product: {
      variantId: string;
      name: string;
      brand: string;
      price: number;
      specialPrice: number | null;
      partNumber: string;
      sku: string;
      img?: string;
    } | null,
  ) => {
    state.recentlyAddedProduct = product;
  };

  return {
    vehicleAskus,
    setVehicleAskus,
    clearVehicleAskus,
    isCartSidebarOpen,
    isVehicleSelectionSidebarOpen,
    isWishlistSidebarOpen,
    isAccountModalOpen,
    isRemoveAddressModalOpen,
    isGarageSelectionSidebarOpen,
    isGarageModalOpen,
    isSignup,
    setIsSignup,
    isLogoutModalOpen,
    isCategoryGridView,
    isFilterSidebarOpen,
    isNavigationSidebarOpen,
    userSelectedVehicle,
    vehiclesInGarage,
    isStoresSidebarOpen,
    addressToRemove,
    toggleStoresSidebar,
    toggleCartSidebar,
    toggleVehicleSelectionSidebar,
    toggleWishlistSidebar,
    toggleAccountModal,
    showAccountModal,
    toggleRemoveAddressModal,
    showRemoveAddressModal,
    toggleLogoutModal,
    closeLogoutModal,
    showLogoutModal,
    toggleCategoryGridView,
    toggleFilterSidebar,
    toggleNavigationSidebar,
    toggleGarageSelectionSidebar,
    setGarageModalOpen,
    initInBrowserState,
    setUserSelectedVehicle,
    setVehiclesInGarage,
    recentlyAddedProduct,
    setRecentlyAddedProduct,
    isRecentlyAddedSidebarOpen,
  };
};

export default useUiState;
