import { onMounted, onUnmounted, Ref, ref } from '@nuxtjs/composition-api';

export interface IBreakpoint {
  name: 'desktop' | 'tablet' | 'mobile';
  value: number;
}

const BREAKPOINTS: IBreakpoint[] = [
  { name: 'desktop', value: 1024 },
  { name: 'tablet', value: 768 },
  { name: 'mobile', value: 0 },
];

const defaultBreakpoint = BREAKPOINTS[2];

export function useBreakpoint(): { breakpoint: Ref<IBreakpoint> } {
  const breakpoint = ref(defaultBreakpoint);

  function resizeHandler() {
    breakpoint.value =
      BREAKPOINTS.find(
        (_breakpoint) => window.matchMedia(`(min-width: ${_breakpoint.value}px)`).matches,
      ) || defaultBreakpoint;
  }

  onMounted(() => {
    window.addEventListener('resize', resizeHandler);
    resizeHandler();
  });

  onUnmounted(() => window.removeEventListener('resize', resizeHandler));

  return { breakpoint };
}
