import { render, staticRenderFns } from "./ButtonPrimary.vue?vue&type=template&id=5ada7e5e&scoped=true&"
import script from "./ButtonPrimary.vue?vue&type=script&lang=ts&"
export * from "./ButtonPrimary.vue?vue&type=script&lang=ts&"
import style0 from "./ButtonPrimary.vue?vue&type=style&index=0&id=5ada7e5e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ada7e5e",
  null
  
)

export default component.exports