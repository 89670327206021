const REVIEW_COUNT_CLASSNAME = 'bv_numReviews_component_container';
const TEXT_NODE_CLASSNAME = 'bv_text';

export default function removeInlineWhenNoReviews(bvElement: Element) {
  const observer = new MutationObserver((mutationList, observer) => {
    for (const mutation of mutationList) {
      if (mutation.type === 'childList' && mutation.addedNodes.length) {
        // this is the element injected by bv that contains all the inline review content
        const injectedDiv = mutation.addedNodes.item(0) as Element;

        // this is the element within the inlie review content that contains the review count
        const container = injectedDiv?.getElementsByClassName?.(REVIEW_COUNT_CLASSNAME).item(0);

        // if we were unable to find the review count container in the mutation, then it must be some other mutation.
        if (!container) {
          continue;
        }

        // this is the element within the review count element that contains the actual text === the review count
        // eslint-disable-next-line no-magic-numbers
        const el = container?.getElementsByClassName?.(TEXT_NODE_CLASSNAME).item(0);

        // if the text is "(0)" there is no reviews. remove the parent dive containing all bv inline review content
        if (el?.textContent === '(0)') {
          injectedDiv?.remove();
        }

        observer.disconnect();
      }
    }
  });

  const config = { attributes: true, childList: true, subtree: true };
  observer.observe(bvElement, config);
}
