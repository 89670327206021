import { SbCategoryDefinitionState } from '~/store/sbCategoryDefinitions';

import { SET_ALL_VENDOR_BRAND_SLUGS } from './constants';

export interface RootState {
  allVendorBrandSlugs: any; // TODO
  sbCategoryDefinitions: SbCategoryDefinitionState;
}

type AllVendorBrandSlugsPayload = any; // TODO

interface RootMutations {
  [SET_ALL_VENDOR_BRAND_SLUGS]: (state: RootState, payload: AllVendorBrandSlugsPayload) => void;
}

export const state = (): RootState => ({
  allVendorBrandSlugs: {},
  sbCategoryDefinitions: {},
});

export const mutations: RootMutations = {
  [SET_ALL_VENDOR_BRAND_SLUGS](state, vendorBrandSlugs) {
    state.allVendorBrandSlugs = vendorBrandSlugs;
  },
};
