import { ISearchResponse } from '@unified-commerce/gpc-vue-storefront-search-io';
import { Collection } from '@unified-commerce/gpc-vue-storefront-shopify';

import {
  BRAND_CATEGORIES_CATEGORY_IDENTIFIER,
  BRANDS_CATEGORY_IDENTIFIER,
  SEARCH_CATEGORY_FILTER,
  SEARCH_CATEGORY_PATH_SEPERATOR,
  SEARCH_CATEGORY_TENANT_PREFIX_HANDLE,
  SEARCH_CATEGORY_TENANT_PREFIX_TITLE,
  SEARCH_FILTER_TITLE_PREFIX,
} from '~/constants';
import { getCategoryExactTitle } from '~/helpers/category/getCategoryExactTitle';
import { getCategoryURLPath } from '~/helpers/category/getCategoryUrlPath';
import { getEncodedCategoryHandle } from '~/helpers/category/getEncodedCategoryHandle';
import { ISearchCategoryFilter } from '~/types/searchio';

export const getSearchCategoryFilters = (
  searchQueryResponse: ISearchResponse,
  shopifyCategoriesResponse: readonly Collection[],
  fromSearchResults = false,
  preAppliedCategory = '',
): ISearchCategoryFilter[] => {
  const filterKey = `${SEARCH_FILTER_TITLE_PREFIX}${SEARCH_CATEGORY_FILTER}`;

  const searchQueryCategoryFilters =
    searchQueryResponse?.aggregateFilters?.[filterKey]?.count?.counts;

  if (!searchQueryCategoryFilters) {
    return [];
  }

  const searchCategoryFilters = Object.entries(searchQueryCategoryFilters)
    .reduce(
      (searchCategoryFilters: ISearchCategoryFilter[], [searchCategoryTitle, productCount]) => {
        const matchedShopifyCategory = shopifyCategoriesResponse.find(
          (shopifyCategory) => shopifyCategory.title === searchCategoryTitle,
        );

        if (
          searchCategoryTitle.startsWith(SEARCH_CATEGORY_TENANT_PREFIX_TITLE) &&
          searchCategoryTitle.includes(SEARCH_CATEGORY_PATH_SEPERATOR) &&
          !searchCategoryTitle.includes(BRANDS_CATEGORY_IDENTIFIER) &&
          !searchCategoryTitle.includes(BRAND_CATEGORIES_CATEGORY_IDENTIFIER) &&
          !!matchedShopifyCategory
        ) {
          const exactTitle = getCategoryExactTitle(searchCategoryTitle);

          const level = searchCategoryTitle.split(SEARCH_CATEGORY_PATH_SEPERATOR).length - 1;

          let ancestors: string[] = [];
          if (level > 1) {
            const ancestorsTitles = searchCategoryTitle.split(SEARCH_CATEGORY_PATH_SEPERATOR);
            ancestorsTitles.shift();
            ancestorsTitles.pop();

            const ancestorHandles = ancestorsTitles.map((ancestorTitle, index) => {
              const lineage = ancestorsTitles.slice(0, index + 1);
              const ancestorTitleWithTenantPrefix = `${SEARCH_CATEGORY_TENANT_PREFIX_TITLE}${SEARCH_CATEGORY_PATH_SEPERATOR}${lineage.join(
                SEARCH_CATEGORY_PATH_SEPERATOR,
              )}`;

              const matchedAncestorShopifyCategory = shopifyCategoriesResponse.find(
                (shopifyCategory) => shopifyCategory.title === ancestorTitleWithTenantPrefix,
              );

              return (
                matchedAncestorShopifyCategory?.handle?.replace(
                  SEARCH_CATEGORY_TENANT_PREFIX_HANDLE,
                  '',
                ) || ''
              );
            });

            ancestors = ancestorHandles;
          }

          const exactHandle = matchedShopifyCategory.handle.replace(
            SEARCH_CATEGORY_TENANT_PREFIX_HANDLE,
            '',
          );

          searchCategoryFilters.push({
            id: matchedShopifyCategory.id,
            encodedHandle: getEncodedCategoryHandle(exactHandle),
            exactHandle,
            urlPath: getCategoryURLPath(ancestors, exactHandle),
            exactTitle,
            level,
            ancestors,
            productCount:
              searchCategoryTitle in searchQueryCategoryFilters
                ? searchQueryCategoryFilters[searchCategoryTitle]
                : productCount,
          });
        }

        return searchCategoryFilters;
      },
      [],
    )
    .sort((categoryA: ISearchCategoryFilter, categoryZ: ISearchCategoryFilter) =>
      categoryA.exactHandle.localeCompare(categoryZ.exactHandle),
    )
    .filter((category) => {
      return !preAppliedCategory.includes(category.exactTitle);
    });

  if (fromSearchResults) {
    const MAX_NUMBER_OF_CATEGORIES = 6;
    return searchCategoryFilters
      .sort((a, b) => b.productCount - a.productCount)
      .slice(0, MAX_NUMBER_OF_CATEGORIES);
  }

  return searchCategoryFilters;
};
