import { IProductResult } from '@unified-commerce/gpc-vue-storefront-search-io';
import { Product } from '@unified-commerce/gpc-vue-storefront-shopify';
import { BundledProductInformation } from '@unified-commerce/gpc-vue-storefront-shopify/apollo/src/types';

import { getSearchIOBundleVariantSummary } from '~/composables/useBundledProductOptions/getSearchIOBundleVariantSummary';
import { getShopifyBundleVariantSummary } from '~/composables/useBundledProductOptions/getShopifyBundleVariantSummary';
import { gidToId } from '~/composables/useBundledProductOptions/shopifyGidUtils';

export const mergeSearchIOAndShopifyProducts = (
  bundles: Partial<BundledProductInformation>[],
  searchIOResults: IProductResult[],
  shopifyProducts: Product[],
) =>
  shopifyProducts.map((product: Product) => {
    const { name, image, options } = getShopifyBundleVariantSummary(product);
    const { parentSKU, variants } = getSearchIOBundleVariantSummary(
      searchIOResults,
      gidToId(product.id),
    );
    const quantityInBundle = bundles.find(({ sku }) => sku === parentSKU)?.quantity ?? 1;

    return {
      name,
      image,
      options,
      quantityInBundle,
      parentSKU,
      variants,
    };
  });
