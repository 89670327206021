import { Image } from '~/components/ArticleCard/types';

type ShopifyCategoryId = string;
interface SbCategoryDefinition {
  id: string;
  name: string;
  component: string;
  seo_title: string;
  description: Record<string, unknown>; // WYSIWYG object
  thumb_image: Image;
  seo_description: string;
}
export interface SbCategoryDefinitionState {
  [ShopifyCategoryId: string]: SbCategoryDefinition;
}

export const state = (): SbCategoryDefinitionState => ({});

export const getters = {
  getSBCategory:
    (state: SbCategoryDefinitionState) =>
    (key: ShopifyCategoryId): SbCategoryDefinition | undefined => {
      if (!key) return;
      return state[key];
    },
};

export const mutations = {
  setCategory: (
    state: SbCategoryDefinitionState,
    payload: { id: ShopifyCategoryId; category: SbCategoryDefinition },
  ) => {
    state[payload.id] = {
      ...payload.category,
      id: payload.id,
    };
  },
};

export const actions = {};
