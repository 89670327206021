export const state = () => ({
  dynamicContentClass: '',
});

export const mutations = {
  SET_DYNAMIC_CONTENT_CLASS(state, className) {
    state.dynamicContentClass = className;
  },
  REMOVE_DYNAMIC_CONTENT_CLASS(state) {
    state.dynamicContentClass = '';
  },
};
