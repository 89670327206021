import {
  ISearchIOVariantSummary,
  ISelectedOption,
  IVariantOption,
} from '~/components/@types/IBundledProductOption';
import { isColorOption, isDefaultTitleOption } from '~/helpers/bundleVariants/bundle-option-utils';

export const findMatchingVariantByOptions = (
  selectedOptions: ISelectedOption[],
  variants: ISearchIOVariantSummary[],
) =>
  variants.find((variant) =>
    variant.values.every((variantOption) =>
      selectedOptions.some((option) => variantOption.value === option.value),
    ),
  );

export const isOptionUnavailable = (
  variants: ISearchIOVariantSummary[],
  option: ISelectedOption,
  selectedOptions?: ISelectedOption[],
  isIncompleteSelection = false,
) => {
  const relevantSelectedOptions = (selectedOptions ?? []).filter((o) => o.name !== option.name);
  if (isIncompleteSelection && relevantSelectedOptions.length === 0) return false;
  const matchingVariant = findMatchingVariantByOptions(
    [option].concat(relevantSelectedOptions),
    variants,
  );
  return !matchingVariant || !matchingVariant.inStock;
};

export const findFirstAvailableOptionValue = (
  options: IVariantOption[],
  variants: ISearchIOVariantSummary[],
  selectedOption: ISelectedOption,
): string => {
  const firstSetOfOptions = options[0]; // e.g. usually "Color"
  const firstAvailableValue = firstSetOfOptions.values.find(
    (value) =>
      !isOptionUnavailable(
        variants,
        {
          name: firstSetOfOptions.name,
          value,
        },
        [selectedOption],
      ),
  );
  if (!firstAvailableValue) {
    throw new Error(`No available options found for ${JSON.stringify(selectedOption)}`);
  }
  return firstAvailableValue;
};

interface IHandleUnavailableOptionSelectionParams {
  options: IVariantOption[];
  variants: ISearchIOVariantSummary[];
  selectedOptions: ISelectedOption[];
  selectedOption: ISelectedOption;
}
export const handleUnavailableOptionSelection = ({
  options,
  variants,
  selectedOptions,
  selectedOption,
}: IHandleUnavailableOptionSelectionParams): ISelectedOption[] => {
  const existingSelectedOptions = selectedOptions
    .filter((option: ISelectedOption) => option.name !== selectedOption.name)
    .concat(selectedOption);

  const incompleteSelection = existingSelectedOptions.length < options.length;
  if (incompleteSelection) {
    return existingSelectedOptions;
  }

  const isAvailable = findMatchingVariantByOptions(existingSelectedOptions, variants)?.inStock;
  if (isAvailable) {
    return existingSelectedOptions;
  }

  const firstOptionSet = options[0]; // e.g., usually "Colour"
  const secondOptionSet = options[1]; // e.g., usually "Size"
  if (selectedOption.name === firstOptionSet.name) {
    // If user selects an option from the first set (Colour) and it's not available,
    // allow them to select it but reset the second option set (Size)
    return existingSelectedOptions.filter((x) => x.name !== secondOptionSet.name);
  }

  // If user selects an option from the second set (Size) and it's not available,
  // allow them to select it and choose the first available option from the first set (Colour)
  const firstAvailableOption = findFirstAvailableOptionValue(options, variants, selectedOption);
  return existingSelectedOptions
    .filter((x: ISelectedOption) => x.name !== firstOptionSet.name)
    .concat([{ name: firstOptionSet.name, value: firstAvailableOption }]);
};

export const setDefaultSelectedOptions = (options: IVariantOption[]): ISelectedOption[] => {
  // Shopify passes the default variant as an option called 'Title' if no variants are present
  const defaultTitleOption = options.find((option) => isDefaultTitleOption(option.name));
  const colorOption = options.find((option) => isColorOption(option.name));

  return [
    colorOption && { name: colorOption.name, value: colorOption.values[0] },
    defaultTitleOption && { name: defaultTitleOption.name, value: defaultTitleOption.values[0] },
  ].filter(Boolean) as ISelectedOption[]; // remove nulls
};

export const getOptionsWithUnavailabilityStatus = (
  options: IVariantOption[],
  variants: ISearchIOVariantSummary[],
  selectedOptions: ISelectedOption[] = [],
): IVariantOption[] => {
  const isIncompleteSelection =
    selectedOptions.length > 0 && selectedOptions.length < options.length;
  return options.map(({ name, values, ...rest }) => ({
    ...rest,
    name,
    values,
    unavailableValues: values.filter((value) =>
      isOptionUnavailable(variants, { name, value }, selectedOptions, isIncompleteSelection),
    ),
  }));
};

export const toVariantSummary = (
  variants: {
    id: string;
    availableForSale: boolean;
    selectedOptions: ISelectedOption[];
  }[],
): ISearchIOVariantSummary[] => {
  return variants.map((variant) => ({
    values: variant.selectedOptions.map(({ value }) => ({ value })),
    variantId: variant.id,
    inStock: variant.availableForSale,
  }));
};
