import { Ref } from '@nuxtjs/composition-api';
import { Collection } from '@unified-commerce/gpc-vue-storefront-shopify';
import kebabCase from 'lodash.kebabcase';
import { Route } from 'vue-router';

import { useUiHelpers } from '~/composables';
import { CATEGORY_ROUTE_PREFIX, SEARCH_CATEGORY_TENANT_PREFIX_HANDLE } from '~/constants';
import { ICategory } from '~/types/storyblok';

const { getActiveCategory } = useUiHelpers();

export const getCategory = (
  route: Route | Ref<Route>,
  shopifyCategories: readonly Collection[],
): Collection | undefined => {
  const categoryHandleSuffix = getActiveCategory(route);
  const fullCategoryHandle = `${SEARCH_CATEGORY_TENANT_PREFIX_HANDLE}${categoryHandleSuffix}`;

  return shopifyCategories.find((shopifyCategory) => shopifyCategory.handle === fullCategoryHandle);
};

export const getCategoryCarouselLink = (category: ICategory) => {
  const collectionTitle =
    category.content?.product_category?.[0]?.shopify_category?.items?.[0]?.name ?? '';
  const path = collectionTitle
    .replace('AMX Categories>', '')
    .split('>')
    .map((segment) => kebabCase(segment))
    .join('/');
  return `/${CATEGORY_ROUTE_PREFIX}/${path}`;
};
