import { render, staticRenderFns } from "./PromotionContent.vue?vue&type=template&id=050d3022&scoped=true&"
import script from "./PromotionContent.vue?vue&type=script&lang=ts&"
export * from "./PromotionContent.vue?vue&type=script&lang=ts&"
import style0 from "./PromotionContent.vue?vue&type=style&index=0&id=050d3022&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "050d3022",
  null
  
)

export default component.exports