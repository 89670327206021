export const load = <T>(key: string): T | null => {
  if (typeof window === 'undefined') return null;
  const item = window.localStorage.getItem(key);
  try {
    return item ? (JSON.parse(item) as T) : null;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const save = <T>(key: string, value: T): void => {
  localStorage.setItem(key, JSON.stringify(value));
};
