import { render, staticRenderFns } from "./SectionTitle.vue?vue&type=template&id=87a2d810&scoped=true&"
import script from "./SectionTitle.vue?vue&type=script&lang=ts&"
export * from "./SectionTitle.vue?vue&type=script&lang=ts&"
import style0 from "./SectionTitle.vue?vue&type=style&index=0&id=87a2d810&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "87a2d810",
  null
  
)

export default component.exports