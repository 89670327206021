type SblokImage = {
  filename: string;
  alt: string;
};

const getPlaceholderImage = (): SblokImage => {
  return {
    filename: 'https://a.storyblok.com/f/146760/1498x994/ef6492966a/placeholder',
    alt: 'Placeholder',
  };
};

export default getPlaceholderImage;
