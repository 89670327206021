import qs from 'qs';

import { CATEGORY_QUERY_PARAM, CATEGORY_ROUTE_PREFIX, PAGE_QUERY_PARAM } from '~/constants';
import { ISearchCategoryFilter } from '~/types/searchio';

export const getCategoryLink = (
  queryParams: Record<string, string | (string | null)[]>,
  category: ISearchCategoryFilter,
  useQueryForCategoryFilters = true,
  fromSearchResults = false,
  basePath = CATEGORY_ROUTE_PREFIX,
): string => {
  const { [PAGE_QUERY_PARAM]: _, ...queryParamsWithoutPage } = queryParams;
  if (useQueryForCategoryFilters && !fromSearchResults) {
    return qs.stringify(
      {
        ...queryParamsWithoutPage,
        [CATEGORY_QUERY_PARAM]: category.encodedHandle,
      },
      {
        addQueryPrefix: true,
      },
    );
  } else if (!useQueryForCategoryFilters && !fromSearchResults) {
    const queryParamsString = qs.stringify(queryParamsWithoutPage, { addQueryPrefix: true });

    return `/${basePath}${category.urlPath}${queryParamsString}`;
  } else {
    return `/${basePath}${category.urlPath}`;
  }
};
