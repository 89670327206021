import { Ref, ref } from '@nuxtjs/composition-api';

import { load, save } from '~/helpers/localStorage';
import { StoreDetails } from '~/types/stores';

const myStore = ref<StoreDetails | null>(load('myStore'));

const storeShortName = (name: string) => name.replace('AMX Superstores ', '');

export const useMyStore = (): {
  myStore: Ref<StoreDetails | null>;
  saveMyStore: (store: StoreDetails) => void;
} => {
  const saveMyStore = (store: StoreDetails): void => {
    const storeToSave = {
      ...store,
      shortName: storeShortName(store.name),
    };
    myStore.value = storeToSave;
    save('myStore', storeToSave);
  };

  return { myStore, saveMyStore };
};
