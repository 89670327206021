import haversineDistance from '~/helpers/haversineDistance';
import { LatLng } from '~/types/stores';

export const distanceFromStoreKm = (
  myLocation: LatLng,
  store: Partial<{ lat: number; lng: number }>,
) => {
  const distanceInMetres = haversineDistance(
    { latitude: myLocation.lat, longitude: myLocation.lng },
    { latitude: store.lat, longitude: store.lng },
  );

  // eslint-disable-next-line no-magic-numbers
  return distanceInMetres / 1000;
};
