import { Ref, ref } from '@nuxtjs/composition-api';
import { usePlaceDetails } from '@unified-commerce/gpc-vue-storefront-google-places';

import { distanceFromStoreKm } from '~/helpers/distanceFromStoreKm';
import { LatLng, StoreDetails, StoreDetailsWithDistance } from '~/types/stores';

import hardCodedStoreDetails from '../../data/storeDetails.json';

const getStoreLocation = (stores: StoreDetails[], placeId: string) => {
  const store = stores.find((store) => store.placeId === placeId);
  if (store) {
    return { lat: store.lat, lng: store.lng };
  }
};

interface Params {
  lat?: number;
  lng?: number;
  placeId?: string;
}

function useNearbyStores(id: string): {
  getNearbyStores: (params: Params) => Promise<StoreDetailsWithDistance[]>;
  error: Ref<unknown | null>;
} {
  const { getPlaceDetails, result, error } = usePlaceDetails(id);

  const stores: StoreDetails[] = Object.values(hardCodedStoreDetails).flat();

  const getNearbyStores = async ({ lat, lng, placeId }: Params) => {
    if (!lat && !lng && !placeId) {
      return [];
    }
    let location = lat && lng ? { lat, lng } : placeId && getStoreLocation(stores, placeId);
    if (!location && placeId) {
      await getPlaceDetails(placeId);
      if (result.value) {
        const { lat, lng } = result.value.result.geometry.location;
        location = { lat, lng };
      }
    }
    if (!location || error.value) {
      return [];
    }

    return Object.values(stores)
      .flatMap((store) => {
        return {
          ...store,
          distance: distanceFromStoreKm(location as LatLng, store),
        };
      })
      .filter((store) => store.distance <= 50);
  };

  return { getNearbyStores, error };
}

export default useNearbyStores;
