import {
  IBundledProductOption,
  IBundleUnselectedOption,
  ISelectedBundledOptions,
  IShopifySelectedOptionMetadata,
} from '~/components/@types/IBundledProductOption';
import {
  findMatchingVariantByOptions,
  getOptionsWithUnavailabilityStatus,
} from '~/helpers/bundleVariants/multi-variant-utils';

export const getProductOptionForSku = (
  productOptions: IBundledProductOption[],
  sku: string,
): IBundledProductOption => {
  const productOptionForSku = productOptions.find(
    (productOption) => productOption.parentSKU === sku,
  );
  if (!productOptionForSku) {
    throw new Error(`No product option found for SKU ${sku}`);
  }
  return productOptionForSku;
};

export const skusForSelectedBundleOptions = (
  bundledProductOptions: IBundledProductOption[],
  selectedBundleOptions: ISelectedBundledOptions,
): IShopifySelectedOptionMetadata[] =>
  Object.entries(selectedBundleOptions).flatMap(
    ([parentSKU, selectedOptions]) =>
      bundledProductOptions
        .filter((v) => v.parentSKU === parentSKU)
        .map(({ variants, quantityInBundle }) => {
          const { variantId } = findMatchingVariantByOptions(selectedOptions, variants) ?? {};
          if (!variantId) {
            return null;
          }

          return {
            parentSKU,
            variantId,
            qty: quantityInBundle,
          };
        })
        .filter(Boolean) as IShopifySelectedOptionMetadata[],
  );

export const optionWithUnavailability =
  (selectedBundleOptions: ISelectedBundledOptions) =>
  (productOption: IBundledProductOption): IBundledProductOption => {
    const selectedOptions = selectedBundleOptions[productOption.parentSKU] ?? undefined;
    if (!selectedOptions) {
      return productOption;
    }
    const options = getOptionsWithUnavailabilityStatus(
      productOption.options,
      productOption.variants,
      selectedOptions,
    );
    return {
      ...productOption,
      options,
    };
  };

export const validateBundleOptions = (
  productOptions: IBundledProductOption[],
  selectedOptions: ISelectedBundledOptions,
): IBundleUnselectedOption[] => {
  return productOptions.reduce<IBundleUnselectedOption[]>((acc, { parentSKU, options }) => {
    const selected = selectedOptions[parentSKU];
    const unselectedOptionNames = options
      .filter((opt) => !selected?.length || !selected.find(({ name }) => name === opt.name))
      .map((opt) => opt.name);

    if (!unselectedOptionNames.length) {
      return acc;
    }

    return [...acc, { parentSKU: parentSKU, unselectedOptionNames }];
  }, []);
};

export const isDefaultTitleOption = (name: string) => /title/i.test(name);
export const isColorOption = (name: string) => /colou?r/i.test(name);
