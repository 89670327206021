import qs from 'qs';

import {
  MAX_PRICE_RANGE_QUERY_PREFIX,
  MIN_PRICE_RANGE_QUERY_PREFIX,
  PAGE_QUERY_PARAM,
  PRICE_RANGE_QUERY_PARAM,
  SEARCH_DEFAULT_FILTERS,
} from '~/constants';
import { IFacet, IFacetOption, ISearchPriceRange, ISearchPriceRangeFilter } from '~/types/searchio';

export const getPriceRangeQueryValue = (priceRange: ISearchPriceRange): string | undefined => {
  if (priceRange.maxPrice && !priceRange.minPrice) {
    return `${MAX_PRICE_RANGE_QUERY_PREFIX}$${priceRange.maxPrice}`;
  } else if (priceRange.minPrice && !priceRange.maxPrice) {
    return `${MIN_PRICE_RANGE_QUERY_PREFIX}$${priceRange.minPrice}`;
  } else if (!priceRange.minPrice && !priceRange.maxPrice) {
    return;
  }

  return `$${priceRange.minPrice}-$${priceRange.maxPrice}`;
};

const getNewPriceRangeQuery = (
  routeQuery: Record<string, string | (string | null)[]>,
  newPriceRange: ISearchPriceRange,
): string[] => {
  const currentPriceRangeQueryValue = routeQuery[PRICE_RANGE_QUERY_PARAM]?.toString() || '';

  const newPriceRangeQueryValue = getPriceRangeQueryValue(newPriceRange) ?? '';

  const isInCurrentPriceRangeQuery = currentPriceRangeQueryValue.includes(newPriceRangeQueryValue);

  if (isInCurrentPriceRangeQuery) {
    if (currentPriceRangeQueryValue === newPriceRangeQueryValue) {
      return [];
    } else {
      return currentPriceRangeQueryValue
        .split(',')
        .filter((appliedPriceRange) => appliedPriceRange !== newPriceRangeQueryValue);
    }
  } else if (currentPriceRangeQueryValue) {
    return [currentPriceRangeQueryValue, newPriceRangeQueryValue];
  }

  return [newPriceRangeQueryValue];
};

export const hasAppliedPriceRangeFilter = (priceRangeFilter: ISearchPriceRangeFilter): boolean => {
  return priceRangeFilter.appliedPriceRanges.length > 0;
};

export const getPriceRangeLink = (
  routeQuery: Record<string, string | (string | null)[]>,
  priceRange: ISearchPriceRange,
): string => {
  return qs.stringify(
    {
      ...routeQuery,
      [PRICE_RANGE_QUERY_PARAM]: getNewPriceRangeQuery(routeQuery, priceRange),
      [PAGE_QUERY_PARAM]: undefined,
    },
    {
      arrayFormat: 'comma',
      addQueryPrefix: true,
    },
  );
};

export const getPriceRangeLabel = (priceRange: ISearchPriceRange): string => {
  if (!priceRange.minPrice && priceRange.maxPrice) {
    return `$0 - $${priceRange.maxPrice}`;
  } else if (priceRange.minPrice && !priceRange.maxPrice) {
    return `Above $${priceRange.minPrice}`;
  }

  return `$${priceRange.minPrice} - $${priceRange.maxPrice}`;
};

export const isPriceRangeSelected = (
  priceRangeFilter: ISearchPriceRangeFilter,
  priceRange: ISearchPriceRange,
): boolean => {
  const { appliedPriceRanges } = priceRangeFilter;

  return (
    hasAppliedPriceRangeFilter(priceRangeFilter) &&
    appliedPriceRanges.findIndex(
      (appliedPriceRange) =>
        appliedPriceRange.maxPrice === priceRange.maxPrice &&
        appliedPriceRange.minPrice === priceRange.minPrice,
    ) > -1
  );
};

// eslint-disable-next-line max-lines-per-function
export const getFacetOptionLink = (
  routeQuery: Record<string, string | (string | null)[]>,
  facet: IFacet,
  option: IFacetOption,
): string => {
  const isFacetInQuery = facet.queryParameterName in routeQuery;
  // [PAGE_QUERY_PARAM]:  undefined set to reset page to 1 when applying new facets
  if (isFacetInQuery) {
    const isFacetOptionInQuery = (routeQuery[facet.queryParameterName] as string)
      .split(',')
      .includes(option.encodedTitle);

    if (isFacetOptionInQuery) {
      return qs.stringify(
        routeQuery[facet.queryParameterName] === option.encodedTitle
          ? {
              ...routeQuery,
              [PAGE_QUERY_PARAM]: undefined,
              [facet.queryParameterName]: [],
            }
          : {
              ...routeQuery,
              [PAGE_QUERY_PARAM]: undefined,
              [facet.queryParameterName]: (routeQuery[facet.queryParameterName] as string)
                .split(',')
                .filter((facetOptionSelected) => facetOptionSelected !== option.encodedTitle),
            },
        {
          addQueryPrefix: true,
          arrayFormat: 'comma',
        },
      );
    }

    return qs.stringify(
      {
        ...routeQuery,
        [PAGE_QUERY_PARAM]: undefined,
        [facet.queryParameterName]: [routeQuery[facet.queryParameterName], option.encodedTitle],
      },
      {
        addQueryPrefix: true,
        arrayFormat: 'comma',
      },
    );
  }

  return qs.stringify(
    {
      ...routeQuery,
      [PAGE_QUERY_PARAM]: undefined,
      [facet.queryParameterName]: option.encodedTitle,
    },
    {
      addQueryPrefix: true,
    },
  );
};

export const isFacetApplied = (
  routeQuery: Record<string, string | (string | null)[]>,
  facet: IFacet,
): boolean => {
  return facet.queryParameterName in routeQuery;
};

export const isFacetOptionSelected = (
  routeQuery: Record<string, string | (string | null)[]>,
  facet: IFacet,
  option: IFacetOption,
): boolean => {
  if (facet.queryParameterName in routeQuery) {
    const facetOptionsSelected = routeQuery[facet.queryParameterName];

    if (facetOptionsSelected.includes(',')) {
      return facetOptionsSelected
        .toString()
        .split(',')
        .some((facetOption) => facetOption === option.encodedTitle);
    }

    return facetOptionsSelected === option.encodedTitle;
  }

  return false;
};

export const getFacetOptionLabel = (_facet: IFacet, option: IFacetOption): string => {
  return `${option.exactTitle} (${option.productCount})`;
};

export const getFacetLabel = (facet: IFacet): string => {
  const singleFilter = SEARCH_DEFAULT_FILTERS.find(
    (filter) => filter.filterKey === facet.encodedTitle,
  );

  if (singleFilter?.label) {
    return singleFilter.label;
  }
  return facet.exactTitle;
};
