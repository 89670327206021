<template>
  <ProductPage
    :product-gallery="productGallery"
    :stock="stock"
    :sku="sku"
    :cart-loading="cartLoading"
    :product-loading="productLoading"
    :product-description-html="productDescriptionHtml"
    :product-name="productTitle"
    :compare-to-price="compareToPrice"
    :price="price"
    :product-recommendation="productRecommendation"
    :options="options"
    :options-not-selected="optionsNotSelected"
    :has-options="hasOptions"
    :variant-by-selected-options="variantBySelectedOptions"
    :variant-image-index="variantImageIndex"
    :selected-options="selectedOptions"
    :product-handle="productHandle"
    :product-vendor="productVendor"
    :details-and-care="detailsAndCare"
    :product-specifications="productSpecifications"
    :product-part-number="productPartNumber"
    :brand-story="brandStory"
    :handling-time="handlingTime"
    :promotion-end-date="promotionEndDate"
    :brand-link="brandLink"
    :asku="asku"
    :breadcrumbs="productCategoriesBreadcrumbs"
    :variant-id="variantId"
    @query-delivery-location="queryDeliveryEstimateLocation"
    @get-delivery-location-details="getDeliveryEstimateLocationDetails"
    @estimate-delivery-quote="estimateDeliveryQuote"
    @add-to-cart="addingToCart"
    @product-option-selected="productOptionSelected"
    @bundled-options-selected="bundledOptionsSelected"
  />
</template>

<script>
/* eslint-disable max-lines-per-function */
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  provide,
  reactive,
  ref,
  useAsync,
  useContext,
  useRoute,
  watch,
} from '@nuxtjs/composition-api';
import {
  placeDetailsGetters,
  useAutocomplete,
  usePlaceDetails,
} from '@unified-commerce/gpc-vue-storefront-google-places';
import {
  getFitmentAskus,
  getFitmentByKtypeParams,
  searchGetters,
  useSearch,
} from '@unified-commerce/gpc-vue-storefront-search-io';
import { useQuote } from '@unified-commerce/gpc-vue-storefront-shippit';
import {
  cartGetters,
  productGetters,
  useCart,
  useProduct,
} from '@unified-commerce/gpc-vue-storefront-shopify';
import { useContent } from '@unified-commerce/gpc-vue-storefront-storyblok';
import getStoryBlokVersion from '@unified-commerce/gpc-vue-storefront-storyblok/shared/helpers/getStoryBlokVersion';
import { sharedRef } from '@vue-storefront/core';
import { isValid, parseISO } from 'date-fns';
import trim from 'lodash/trim';

import ProductPage from '~/components/Pages/ProductPage/ProductPage.vue';
import { useMyGarage, useRecentlyViewedProducts, useUiState } from '~/composables';
import useBrandLink from '~/composables/useBrandLink';
import { useBundledProductOptions } from '~/composables/useBundledProductOptions';
import useUiHelpers from '~/composables/useUiHelpers';
import useUiNotification from '~/composables/useUiNotification';
import { getProductFailedToAddCartNotification } from '~/composables/useUiNotification/commonNotifications';
import analytics from '~/helpers/analytics';
import {
  getOptionsWithUnavailabilityStatus,
  handleUnavailableOptionSelection,
  setDefaultSelectedOptions,
  toVariantSummary,
} from '~/helpers/bundleVariants/multi-variant-utils';
import {
  INJECTION_KEY_DEAL_EXPIRY,
  INJECTION_KEY_DELIVERY_ESTIMATE_LOCATION_ERROR,
  INJECTION_KEY_DELIVERY_ESTIMATE_LOCATION_LOADING,
  INJECTION_KEY_DELIVERY_ESTIMATE_LOCATION_RESULT,
  INJECTION_KEY_DELIVERY_ESTIMATE_QUOTE_ERROR,
  INJECTION_KEY_DELIVERY_ESTIMATE_QUOTE_LOADING,
  INJECTION_KEY_DELIVERY_ESTIMATE_QUOTE_RESULT,
  INJECTION_KEY_HAS_DEAL_EXPIRY,
} from '~/helpers/injectionKeys/productPage';
import getFitmentByAskusParams from '~/helpers/search/getFitmentByAskusParams';
import getFitmentWithAskuParams from '~/helpers/search/getFitmentWithAskuParams';
import { sanitiseProductId } from '~/helpers/shopify';

export const NOTIFICATION_KEY_PRODUCT_ADDED = 'product_added';

const ADDITIONAL_FIELDS = [
  { name: 'material', field: 'material' },
  { name: 'materialThickness', field: 'material_thickness' },
];

export default defineComponent({
  name: 'ProductPageRoute',
  components: {
    ProductPage,
  },
  transition: 'fade',
  // eslint-disable-next-line max-lines-per-function
  setup(_props, context) {
    const { $config } = useContext();
    const { slug } = context.root.$route.params;
    const route = useRoute();
    const { userSelectedVehicle, setVehicleAskus, vehicleAskus, setRecentlyAddedProduct } =
      useUiState();
    const { send: sendNotification } = useUiNotification();
    const { setCurrentRouteAsNotFound, setCurrentRouteAsInternalError } = useUiHelpers();
    const options = ref([]);
    const optionsNotSelected = ref([]);
    const contentLoading = sharedRef(true, `content-loading-${slug}`);
    const selectedBundleVariants = ref(null);
    const unselectedBundleVariants = ref(null);
    const hasBundledProductValidationError = ref(false);
    const selectedOptions = reactive([]);

    const {
      loading: productLoading,
      products,
      search,
      error: productError,
    } = useProduct(`products ${slug}`);

    const { bundledProductOptions, getBundledProductOptions } = useBundledProductOptions(slug);

    const { cart, addItem, loading: cartLoading, error: cartError } = useCart();

    const {
      query: queryDeliveryEstimateLocation,
      loading: deliveryEstimateLocationLoading,
      error: deliveryEstimateLocationError,
      result: deliveryEstimateLocationResult,
    } = useAutocomplete(`deliveryEstimateLocation-${slug}`);

    const {
      getPlaceDetails: getDeliveryEstimateLocationDetails,
      result: deliveryEstimateLocationDetailsResult,
    } = usePlaceDetails(`deliveryEstimateLocationDetails-${slug}`);

    const {
      getQuote: getDeliveryEstimateQuote,
      clearQuote: clearDeliveryEstimateQuote,
      loading: deliveryEstimateQuoteLoading,
      error: deliveryEstimateQuoteError,
      result: deliveryEstimateQuoteResult,
    } = useQuote(`deliveryEstimateQuote-${slug}`);

    const productPath = computed(() => trim(route.value.path, '/'));

    const { search: searchProductRecommendation, content = {} } = useContent(productPath.value);

    const { search: searchBrand, content: brandContent = {} } = useContent(`brandSearch-${slug}`);

    const brandStory = computed(() => {
      if (brandContent.value && brandContent.value[0]) {
        return brandContent.value[0];
      }

      return null;
    });

    const product = computed(
      () =>
        productGetters.getFiltered(products.value, {
          master: true,
          attributes: context?.root?.$route?.query,
        })[0],
    );

    const { pushRecentlyViewedProduct } = useRecentlyViewedProducts();
    onBeforeUnmount(() => {
      pushRecentlyViewedProduct(product.value);
    });

    const brandLink = computed(() => useBrandLink(product.value));
    const id = computed(() => (product.value ? productGetters.getId(product.value) : ''));

    const productTitle = computed(() =>
      product.value ? productGetters.getName(product.value) : '',
    );

    const productHandle = computed(() => productGetters.getSlug(product.value));

    const productDescriptionHtml = computed(() =>
      product.value ? productGetters.getDescription(product.value, true) : '',
    );

    const price = computed(() => productGetters.getProductPrice(product.value).price);

    const variantId = computed(() => {
      if (product.value) {
        if (hasVariantSelected.value) {
          return sanitiseProductId(variantBySelectedOptions?.value?.id);
        }

        return sanitiseProductId(product.value?.variants?.[0]?.id);
      }

      return '';
    });

    const compareToPrice = computed(
      () => productGetters.getProductPrice(product.value).compareToPrice,
    );

    const hasDealExpiry = computed(() =>
      product.value ? productGetters.hasDealExpiry(product.value) : false,
    );
    const dealExpiry = computed(() =>
      product.value ? productGetters.getDealExpiryDate(product.value) : '',
    );

    const promotionEndDate = computed(() => {
      if (!product.value) {
        return null;
      }

      const endDate = productGetters.getPromotionEndDate(product.value);
      const parsedEndDate = parseISO(endDate);

      if (isValid(parsedEndDate)) {
        return parsedEndDate;
      } else if (endDate) {
        console.warn(
          `Product ${product.value.title} promotion end date ${endDate} is not a valid date format.`,
        );
      }
    });

    const hasOptions = computed(() =>
      product.value ? productGetters.hasOptions(product.value) : false,
    );

    const variantBySelectedOptions = computed(() =>
      product.value ? productGetters.getVariantBySelectedOptions(product.value) : {},
    );
    const hasVariantSelected = computed(() =>
      product.value ? productGetters.hasVariantSelected(product.value) : false,
    );

    const variantImageIndex = computed(() =>
      product.value
        ? productGetters.getVariantImageIndex(
            product.value,
            hasVariantSelected.value ? variantBySelectedOptions.value.image.originalSrc : '',
          )
        : 0,
    );

    const selectedVariantId = computed(() =>
      hasOptions.value
        ? selectedOptions.length !== options.value.length
          ? null
          : product.value?.variantBySelectedOptions?.id
        : product.value?.variantId,
    );

    const productGallery = computed(() => {
      if (product.value && product.value.images.length === 0) {
        product.value.images.push({
          originalSrc:
            'https://cdn.shopify.com/s/files/1/0407/1902/4288/files/placeholder_600x600.jpg?v=1625742127',
        });
      }

      return productGetters.getGallery(product.value).map((media) => ({
        mobile: { url: media.small },
        desktop: { url: media.normal },
        big: { url: media.big },
        alt: product.value?.name || '',
        video: media.video,
      }));
    });

    const stock = computed(() => {
      return product.value ? productGetters.getStock(product.value) : 0;
    });

    const sku = computed(() => productGetters.getSKU(product.value));

    const productRecommendation = computed(() => {
      if (content.value['0']) {
        return content.value['0'].content;
      }

      return null;
    });

    const productVendor = computed(() => productGetters.getVendorName(product.value));

    const detailsAndCare = computed(() => {
      return {
        contentType: 'html',
        title: 'Details & Care',
        content: productGetters.getProductVariantMetafield(product.value, 'detail_care'),
      };
    });

    const handlingTime = computed(() =>
      productGetters.getProductVariantMetafield(product.value, 'handlingTime'),
    );

    const productSpecifications = computed(() => {
      return {
        contentType: 'attribute-list',
        title: 'Specifications',
        content: productGetters.getProductSpecification(product.value),
      };
    });

    const productPartNumber = computed(() =>
      productGetters.getProductVariantMetafield(product.value, 'partNumber'),
    );

    // fitment data injections
    const asku = computed(() => productGetters.getProductVariantMetafield(product.value, 'asku'));

    const { search: searchFitment, result: fitmentResult } = useSearch(
      `${productPath.value}-get-fitment-data`,
    );

    const { search: searchUniversal, result: fitmentUniversalResult } = useSearch(
      `${productPath.value}-get-universal-fitment-data`,
    );

    const getFitmentData = async () => {
      const selectedVehicleCookie = await useMyGarage().getVehicleSelectionFromCookie();
      if (selectedVehicleCookie) {
        await searchFitment(getFitmentByAskusParams([asku.value], selectedVehicleCookie.KtypNr));
      }
    };

    provide('contentLoading', contentLoading);

    provide(
      'isUniversalProduct',
      computed(() => searchGetters.hasNoItems(fitmentUniversalResult.value)),
    );

    // not using watch causes fitment data not to be injected reactively
    watch(asku, getFitmentData, { immediate: true });
    provide(
      'fitmentSearchData',
      computed(() => searchGetters.getItems(fitmentResult.value)),
    );
    // end fitment data injection
    const productCategoriesBreadcrumbs = computed(() =>
      productGetters.getProductCategoryBreadcrumbs(product.value, 'AMX', {
        text: 'All Categories',
        link: '/motorcycle-gear',
      }),
    );

    provide(
      'userSelectedVehicle',
      computed(() => userSelectedVehicle.value),
    );
    provide(
      'productFitsVehicle',
      computed(() => {
        return vehicleAskus.value?.askus?.includes(asku.value) ? true : false;
      }),
    );

    provide('bundledProductOptions', bundledProductOptions);

    provide('selectedBundleVariants', selectedBundleVariants);

    provide('hasBundledProductValidationError', hasBundledProductValidationError);

    provide('variantId', selectedVariantId);

    watch(product, () => {
      if (product && product.value) {
        analytics({
          event: 'view_item',
          product: product.value,
          discount: compareToPrice?.value,
          price: price?.value,
        });
      }
    });

    useAsync(async () => {
      const version = getStoryBlokVersion($config.ENV, route.value);
      contentLoading.value = true;
      await search({
        slug,
        additionalFields: ADDITIONAL_FIELDS,
      });

      if (asku.value) {
        await searchUniversal(getFitmentWithAskuParams(asku.value));
      }

      const vehicleSelectedCookie = await useMyGarage().getVehicleSelectionFromCookie();

      if (!product.value) {
        setCurrentRouteAsNotFound({
          errorMessage: 'This product could not be found',
          data: [product, vehicleAskus, userSelectedVehicle, vehicleSelectedCookie],
        });
      } else if (productError.value.search) {
        setCurrentRouteAsInternalError({
          data: {
            error: productError.value.search,
            data: product.value,
          },
        });
      } else {
        try {
          await getBundledProductOptions(product.value);
        } catch (error) {
          console.error('Failed to fetch bundled products', error);
        }

        await Promise.allSettled([
          searchProductRecommendation({
            version,
            relations: 'ProductRecommendation.expert,ProductRecommendation.youtube_video',
            starts_with: 'product-recommendations/',
            custom: {
              'filter_query[product.items.0.id][in]': id.value,
            },
            optimisticStoryLoading: true,
          }),
          searchBrand({
            version,
            relations: 'BrandHero.brand',
            custom: {
              'filter_query[name][in]': `${productVendor.value}`,
              'filter_query[component][in]': 'Brand',
            },
            optimisticStoryLoading: true,
          }),
        ]).then(() => (contentLoading.value = false));
      }
      contentLoading.value = false;
    });

    const addingToCart = async (quantity) => {
      if (
        bundledProductOptions.value?.length &&
        (!selectedBundleVariants.value ||
          selectedBundleVariants.value.length < bundledProductOptions.value.length)
      ) {
        hasBundledProductValidationError.value = true;
        return;
      }

      hasBundledProductValidationError.value = false;

      if (hasOptions.value && selectedOptions.length !== options.value.length) {
        const selectedOptionsList = selectedOptions.map((selectedOption) => selectedOption.name);

        options.value.forEach((option) => {
          if (
            !selectedOptionsList.includes(option.name) &&
            !optionsNotSelected.value.includes(option.name)
          ) {
            optionsNotSelected.value.push(option.name);
          }
        });
        return;
      }

      const customQuery = selectedBundleVariants.value
        ? { key: '__bundleSelectedVariants', value: JSON.stringify(selectedBundleVariants.value) }
        : undefined;

      await addItem({
        product: product.value,
        quantity,
        customQuery,
      });

      analytics({
        event: 'add_to_cart',
        id: product?.value?.id,
        name: product?.value?.name,
        price: price?.value,
        vendor: product?.value?.vendor,
        category: productCategoriesBreadcrumbs?.value?.[0]?.text,
      });

      if (cartError.value.addItem) {
        sendNotification(
          getProductFailedToAddCartNotification({
            name: product.value.name,
          }),
        );
      } else {
        setRecentlyAddedProduct({
          variantId: product.value.id,
          name: product.value.name,
          brand: product.value.vendor,
          price: product.value.price.original || product.value.price.current,
          specialPrice: product.value.price.original ? product.value.price.current : null,
          partNumber: productPartNumber.value,
          sku: sku.value,
          img: productGallery.value[0]?.mobile?.url || '',
        });
      }
    };

    const estimateDeliveryQuote = async (locationDetails) => {
      const location = placeDetailsGetters.getLocation(
        locationDetails ? locationDetails : deliveryEstimateLocationDetailsResult.value,
      );

      if (!locationDetails && window?.localStorage) {
        localStorage.setItem(
          'deliveryLocation',
          JSON.stringify(deliveryEstimateLocationDetailsResult.value.result),
        );
      }

      const productWeight = productGetters.getWeight(product.value);

      await getDeliveryEstimateQuote({
        postcode: location.postcode,
        state: location.state,
        suburb: location.suburb,
        products: [
          {
            qty: 1,
            weight: productWeight,
          },
        ],
      });
    };

    onMounted(() => {
      clearDeliveryEstimateQuote();
    });

    const productOptionSelected = async (selectedOption) => {
      optionsNotSelected.value = [];

      const variants = toVariantSummary(product.value.variants);
      const updatedSelectedOptions = handleUnavailableOptionSelection({
        options: options.value,
        variants,
        selectedOptions,
        selectedOption,
      });

      // Empty array and reset with new values
      selectedOptions.length = 0;
      Object.assign(selectedOptions, updatedSelectedOptions);

      options.value = getOptionsWithUnavailabilityStatus(
        options.value,
        variants,
        updatedSelectedOptions,
      );

      if (options.value.length === selectedOptions.length) {
        await search({ slug, selectedOptions, additionalFields: ADDITIONAL_FIELDS });
      }
    };

    const bundledOptionsSelected = ({ unselectedBundleOptions, selectedOptionMetadata }) => {
      if (unselectedBundleOptions.length === 0) {
        hasBundledProductValidationError.value = false;
      }
      unselectedBundleVariants.value = unselectedBundleOptions;
      selectedBundleVariants.value = selectedOptionMetadata;
    };

    watch(userSelectedVehicle, async (userSelectedVehicle) => {
      if (userSelectedVehicle) {
        await searchFitment(getFitmentByKtypeParams(userSelectedVehicle.KtypNr));
        const askus = getFitmentAskus(fitmentResult.value);

        if (askus) {
          setVehicleAskus(askus, userSelectedVehicle.KtypNr);
          searchFitment(getFitmentByAskusParams([asku.value], userSelectedVehicle.KtypNr));
        }
      }
    });

    watch(
      [product, selectedOptions],
      () => {
        if (!product.value) {
          return;
        }
        const selectedOptionsWithDefault =
          selectedOptions.length === 0
            ? setDefaultSelectedOptions(productGetters.getOptions(product.value, true))
            : selectedOptions;

        if (selectedOptions.length === 0) {
          selectedOptions.push(...selectedOptionsWithDefault);
        }

        options.value = getOptionsWithUnavailabilityStatus(
          productGetters.getOptions(product.value, true),
          toVariantSummary(product.value.variants),
          selectedOptionsWithDefault,
        );
      },
      { immediate: true },
    );

    provide(
      INJECTION_KEY_DELIVERY_ESTIMATE_LOCATION_LOADING,
      computed(() => deliveryEstimateLocationLoading.value),
    );
    provide(
      INJECTION_KEY_DELIVERY_ESTIMATE_LOCATION_ERROR,
      computed(() => deliveryEstimateLocationError.value),
    );
    provide(
      INJECTION_KEY_DELIVERY_ESTIMATE_LOCATION_RESULT,
      computed(() => deliveryEstimateLocationResult.value),
    );
    provide(
      INJECTION_KEY_DELIVERY_ESTIMATE_QUOTE_LOADING,
      computed(() => deliveryEstimateQuoteLoading.value),
    );
    provide(
      INJECTION_KEY_DELIVERY_ESTIMATE_QUOTE_ERROR,
      computed(() => deliveryEstimateQuoteError.value),
    );
    provide(
      INJECTION_KEY_DELIVERY_ESTIMATE_QUOTE_RESULT,
      computed(() => deliveryEstimateQuoteResult.value),
    );
    provide(
      INJECTION_KEY_DEAL_EXPIRY,
      computed(() => dealExpiry.value),
    );
    provide(
      INJECTION_KEY_HAS_DEAL_EXPIRY,
      computed(() => hasDealExpiry.value),
    );

    return {
      product,
      productDescriptionHtml,
      sendNotification,
      stock,
      productTitle,
      addItem,
      cartLoading,
      productLoading,
      productGallery,
      productHandle,
      productGetters,
      cartGetters,
      sku,
      price,
      compareToPrice,
      addingToCart,
      productRecommendation,
      promotionEndDate,
      dealExpiry,
      hasDealExpiry,
      options,
      optionsNotSelected,
      hasOptions,
      productOptionSelected,
      variantBySelectedOptions,
      hasVariantSelected,
      variantImageIndex,
      selectedOptions,
      deliveryEstimateLocationResult,
      queryDeliveryEstimateLocation,
      getDeliveryEstimateLocationDetails,
      estimateDeliveryQuote,
      productVendor,
      productSpecifications,
      handlingTime,
      detailsAndCare,
      productPartNumber,
      asku,
      brandStory,
      brandLink,
      productCategoriesBreadcrumbs,
      bundledProductOptions,
      bundledOptionsSelected,
      variantId,
    };
  },
  head() {
    const { path } = this.$route;

    if (this.product) {
      const structuredData = {
        '@context': 'https://schema.org/',
        '@type': 'Product',
        '@id': `https://${this.$config.BASE_URL}${trim(path)}`,
        name: this.product.title,
        image: this.productGallery.map((image) => image.desktop.url),
        description: this.product.description,
        keywords: (this.product.tags || []).join(', '),
        sku: productGetters.getSKU(this.product),
        brand: {
          '@type': 'Brand',
          name: this.product.vendor || '',
        },
        offers: {
          '@type': 'Offer',
          url: `https://${this.$config.BASE_URL}${trim(path)}`,
          priceCurrency: 'AUD',
          price: this.price ? this.price.toString() : '',
          itemCondition: 'https://schema.org/NewCondition',
          availability:
            this.stock > 0 ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock',
        },
      };

      if (productGetters.isDeal(this.product)) {
        structuredData.offers.sale_price = this.price ? this.price.toString() : '';
        structuredData.offers.price = this.compareToPrice ? this.compareToPrice.toString() : '';
        structuredData.offers.priceValidUntil = productGetters.getDealExpiryDate(this.product);
      }

      return {
        title: this.product.title,
        meta: [
          {
            hid: 'description',
            name: 'description',
            content: this.product.description,
          },
          {
            hid: 'og:url',
            property: 'og:url',
            content: `https://${this.$config.BASE_URL}${trim(path)}`,
          },
          {
            hid: 'og:title',
            property: 'og:title',
            content: this.product.title,
          },
          {
            hid: 'og:type',
            property: 'og:type',
            content: 'product',
          },
          {
            hid: 'og:description',
            property: 'og:description',
            content: this.product.description,
          },
          {
            hid: 'og:image',
            property: 'og:image',
            content: this.productGallery[0].desktop.url,
          },
          {
            hid: 'og:image:secure_url',
            property: 'og:image:secure_url',
            content: this.productGallery[0].desktop.url,
          },
          {
            hid: 'og:price:amount',
            property: 'og:price:amount',
            content: this.price ? this.price.toString() : '',
          },
          {
            hid: 'og:price:currency',
            property: 'og:price:currency',
            content: 'AUD',
          },
          {
            hid: 'twitter:title',
            name: 'twitter:title',
            content: this.product.title,
          },
          {
            hid: 'twitter:description',
            name: 'twitter:description',
            content: this.product.description,
          },
          {
            hid: 'twitter:image',
            name: 'twitter:image',
            content: this.productGallery[0].desktop.url
              ? this.productGallery[0].desktop.url
              : '~/static/icons/4wd-app-icon-512x512.png',
          },
          {
            hid: 'twitter:card',
            name: 'twitter:card',
            content: this.productGallery[0].desktop.url ? 'summary_large_image' : 'summary',
          },
        ],
        script: [
          {
            type: 'application/ld+json',
            json: structuredData,
          },
        ],
      };
    }

    return {};
  },
});
</script>
