var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Container',{staticClass:"feat-videos"},[_c('CardSection',{staticClass:"video-grid",attrs:{"title":_vm.title,"description":_vm.description,"link":_vm.linkUrl,"link-text":_vm.link_text,"mobile":{
      cardMode: 'scroll',
      cardFlexBasis: '301px',
      showDescription: false,
      showSteppers: false,
    },"tablet":{
      cardMode: 'wrap',
      cardFlexBasis: 'calc(100% / 3)',
      cardFlexGrow: 1,
      showDescription: true,
    }}},_vm._l((_vm.videos),function(video,i){return _c('div',{key:i},[(_vm.isYoutubeVideo(video))?_c('VideoCard',{attrs:{"media-src":_vm.getYoutubeVideoURL(video),"title":video.content.title,"description":video.content.description,"author":video.content.author.name,"link":video.full_slug,"show-link-text":true}}):_vm._e()],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }